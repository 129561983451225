import { apiCall } from '../utils/helpers';
import { GET_LIST_CUSTOMERS } from '../redux/customers';
import { parserDataCustomer } from '../utils/parser';
import { LOADING_STARTED, LOADING_STOPED } from '../redux/loader';

export async function getCustomers(store = {}, rowsCount) {
  try {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });
    const result = await apiCall({
      method: 'get',
      url: `/users/0/${rowsCount || 10}?role=CLIENT`,
    });
    if (result?.data?.data?.counts > 0) {
      await parserDataCustomer(
        result?.data?.data?.users,
        store,
        result?.data?.data?.counts,
      );
      /*store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });*/
    } else {
      store.dispatch({
        type: GET_LIST_CUSTOMERS,
        payload: {
          data: [],
        },
      });
      /* store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });*/
    }
  } catch (error) {
    store.dispatch({
      type: LOADING_STOPED,
      payload: {},
    });
  }
}
