import { apiCall } from '../utils/helpers';
import {
  GET_LIST_CUSTOMERS,
  GET_LIST_SEARCH,
  GET_LIST_SEARCH_CUSTOMER,
} from '../redux/customers';
import { parserDataCustomer } from '../utils/parser';
import { LOADING_STARTED, LOADING_STOPED } from '../redux/loader';
import { GET_LIST_SEARCH_RP } from '../redux/rp';

export async function search(store = {}, role, search) {
  try {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });
    const result = await apiCall({
      method: 'get',
      url: `/users?role=${role}&firstName=${search || ''}`,
    });
    if (result?.data?.data?.counts > 0) {
      if (role === 'RP') {
        store.dispatch({
          type: GET_LIST_SEARCH_RP,
          payload: {
            data: result?.data?.data?.users,
          },
        });
      } else {
        store.dispatch({
          type: GET_LIST_SEARCH_CUSTOMER,
          payload: {
            data: result?.data?.data?.users,
          },
        });
      }
    } else {
      if (role === 'RP') {
        store.dispatch({
          type: GET_LIST_SEARCH_RP,
          payload: {
            data: [],
          },
        });
      } else {
        store.dispatch({
          type: GET_LIST_SEARCH_CUSTOMER,
          payload: {
            data: [],
          },
        });
      }
    }
  } catch (error) {
    store.dispatch({
      type: LOADING_STOPED,
      payload: {},
    });
  }
}
