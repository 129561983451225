import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {styled} from "@material-ui/core/styles";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
export default function PeopleDialog({ open, handleClose, body }) {
  return (
    <>
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        aria-describedby="alert-dialog-description"
        aria-labelledby="form-dialog-title"
      >
        {body}
      </BootstrapDialog>
    </>
  );
}
