import toast from '../utils/toast';
import { apiCall } from '../utils/helpers';
import { ADD_RP, UPDATE_RP } from '../redux/rp';

export async function createUpdateRp(id, values, store = {}, index) {
  if (id) delete values.role;

  const result = await apiCall({
    method: id ? 'patch' : 'post',
    url: id ? '/users/' + id : '/users',
    data: values,
  });

  if (result?.data?.success) {
    if (id)
      store.dispatch({
        type: UPDATE_RP,
        payload: {
          rp: result?.data?.data?.user,
          index,
        },
      });
    else
      store.dispatch({
        type: ADD_RP,
        payload: {
          rp: result?.data?.data?.user,
        },
      });
  } else {
    const message = result?.data?.message ? result?.data?.message : 'error occured';
    toast.showError(store, message);
  }
  return result?.data;
}
