import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Snackbar from '@material-ui/core/Snackbar';
import Content from '../dashboard/Content';
import MuiAlert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { Profil } from './containers/Profil';
import { Password } from './containers/Password';
import { makeStyles, styled, useTheme } from '@material-ui/core/styles';
import colors from '../../utils/colors';
import { SHOW_SETTINGS_PAGE } from '../../redux/settings';
import { useSelector, useStore } from 'react-redux';
import HeaderPage from '../../Components/HeaderPage';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: 10,
    backgroundColor: colors.white,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  grow: {
    flexGrow: 1,
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: colors.yellow,
    color: 'white',
    borderRadius: 15,
  },
  icon: {
    color: colors.yellow,
    padding: 5,
    fontSize: 30,
  },
  iconNote: {
    color: colors.yellow,
    fontSize: 15,
  },
  logo: {
    marginBottom: '1%',
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    color: 'black',
    textDecoration: 'none',
    textTransform: 'capitalize',
  },
}));

const AntTab = styled(Tab)({
  root: {
    textTransform: 'none',
  },
});
const AntTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: colors.white,
  },
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
export default function Settings() {
  const classes = useStyles();
  const theme = useTheme();
  const store = useStore();
  const [value, setValue] = useState(1);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const showSettingsPage = useSelector((state) => state?.settings?.showSettingsPage);
  const userProfile = useSelector((state) => state?.settings?.userProfile);
  const pageName = useSelector((state) => state?.settings?.pageName);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    store.dispatch({
      type: SHOW_SETTINGS_PAGE,
      payload: {
        showSettingsPage: newValue,
      },
    });
  };
  const snackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackOpen(false);
  };
  return (
    <Content>
      <div style={{ alignItems: 'flex-end', display: 'flex' }}>
        <HeaderPage />
      </div>
      <Snackbar open={snackOpen} autoHideDuration={2000} onClose={snackClose}>
        <Alert onClose={snackClose} severity="success">
          {snackOpen}
        </Alert>
      </Snackbar>
      <div className={classes.root}>
        <div style={{ width: '100%' }}>
          <AntTabs
            value={showSettingsPage || value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <AntTab value={1} label="Modifier profil" />
            <AntTab value={2} label="Mot de passe" />
          </AntTabs>
        </div>
        <div style={{ width: '100%' }}>
          <TabPanel value={showSettingsPage || value} index={1} dir={theme.direction}>
            <Profil userProfile={userProfile} />
          </TabPanel>
          <TabPanel value={showSettingsPage || value} index={2} dir={theme.direction}>
            <Password />
          </TabPanel>
        </div>
      </div>
    </Content>
  );
}
