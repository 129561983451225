import { configureStore } from '@reduxjs/toolkit';
import settingsReducer from './settings';
import loaderReducer from './loader';
import customerSlice from './customers';
import rpSlice from './rp';
import reservationSlice from './reservation';
import toastSlice from './toast';
import { createBrowserHistory } from 'history';
import { routerMiddleware, routerReducer } from 'react-router-redux';
import thunk from 'redux-thunk';

const history = createBrowserHistory();
const middlewares = [thunk, routerMiddleware(history)];

export const store = configureStore({
  reducer: {
    settings: settingsReducer,
    customers: customerSlice,
    loader: loaderReducer,
    rp: rpSlice,
    reservation: reservationSlice,
    toast: toastSlice,
    router: routerReducer,
  },
  middlewares,
});
