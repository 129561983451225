import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import Title from './Title';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

function useOptions(theme, data, secondData) {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  return {
    chart: {
      renderTo: 'container',
      type: 'spline',
    },
    title: {
      text: 'Line chart',
      style: {
        display: 'none',
      },
    },
    legend: {
      align: 'left',
      verticalAlign: 'top',
      symbolWidth: 5,
      enabled: false,
      symbolHeight: 8,
    },
    xAxis: {
      tickColor: '#FFFFFF',
      categories: months,
    },
    yAxis: {
      tickColor: '#FFFFFF',
      gridLineColor: '#FFFFFF',
      title: {
        style: {
          display: 'none',
        },
      },
      labels: {
        formatter: function () {
          return `${this.value}`;
        },
      },
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
      },
    },
    tooltip: {
      shared: false,
    },

    series: [
      {
        marker: {
          symbol: 'diamond',
        },
        name: '',
        color: '#2d77b2',
        data: data,
      },
    ],
  };
}

export default function Chart({ reservationsByMonth }) {
  const theme = useTheme();
  const options = useOptions(theme, reservationsByMonth);
  return (
    <React.Fragment>
      <Title>Nombre de réservations par mois</Title>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </React.Fragment>
  );
}
