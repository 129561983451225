import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    depositContext: {
        flex: 1,
        fontSize: 13,
        width: '20vh',
    },
    total: {
        fontSize: 15,
        fontWeight: 'bold',
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    balance: {
        display: 'flex',
        alignItems: 'center',
    },
    balanceItem: {
        marginRight: '40px',
    },
});

export default function Deposits({ name, total, icon }) {
    const classes = useStyles();

    return (
        <React.Fragment>
            <div className={classes.balance}>
                {icon}
                <div className={classes.balanceItem}>
                    <Typography color="textSecondary" className={classes.depositContext}>
                        {name}
                    </Typography>
                    <Typography component="p" className={classes.total}>
                        {total}
                    </Typography>
                </div>
            </div>
        </React.Fragment>
    );
}
