import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    headerContainer: {
        position: 'relative',
        height: '100px',
    },
    header: {
        display: 'flex',
        position: 'absolute',
        width: 'calc(100%)',
        top: '-70px',
        alignItems: 'flex-end',
        '& > *': {
            margin: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
        },
    },
    spacer: {
        flexGrow: '1',
    },
    avatar: {
        border: `3px solid white`,
        width: theme.spacing(13),
        height: theme.spacing(13),
        boxShadow: theme.shadows[3],
    },
    actionGroup: {
        display: 'flex',
        width: '330px',
        justifyContent: 'space-between',
        marginRight: 0,
    },
    summaryCards: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    summaryCard: {
        margin: theme.spacing(1),
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    tripCard: {
        margin: theme.spacing(1),
        padding: theme.spacing(2),
    },
}));

export function SummaryCard({ title, value, component }) {
    const classes = useStyles();
    return (
        <Paper elevation={2} className={classes.summaryCard}>
            <Typography color={'textSecondary'} variant="h5" gutterBottom>
                {title}
            </Typography>
            {component || (
                <Typography color={'primary'} variant="h3">
                    {value}
                </Typography>
            )}
        </Paper>
    );
}
