import { apiCall } from '../utils/helpers';
import { LOADING_STOPED } from '../redux/loader';
import { GET_RESERVATION } from '../redux/reservation';

export async function getReservationById(id, store) {
  try {
    const url = `/reservations/${id}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    if (data.success) {
      store.dispatch({
        type: GET_RESERVATION,
        payload: {
          reservation: data?.data?.reservation,
        },
      });
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
    } else {
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
    }
    return data;
  } catch (err) {
    store.dispatch({
      type: LOADING_STOPED,
      payload: {},
    });
  }
  return true;
}
