import React from 'react';
import Grid from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import moment from 'moment';
import BootstrapDialogTitle from '../../../Components/BootstrapDialogTitle';
import { useSelector, useStore } from 'react-redux';
import { RESET_RESERVATION } from '../../../redux/reservation';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import { statusReservation } from '../../../utils/helpers';

export function ShowReservation({ setOpenModal }) {
  const store = useStore();
  const reservation = useSelector((state) => state?.reservation?.reservation);
  return (
    <div>
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => {
          store.dispatch({
            type: RESET_RESERVATION,
            payload: {},
          });
          setOpenModal(false);
        }}
      >
        Détails
      </BootstrapDialogTitle>

      <DialogContent>
        <Grid style={{ alignItems: 'center' }} md={12} container>
          <Grid style={{ alignItems: 'center' }} md={1} container></Grid>
          <Grid md={11} container>
            <Grid item xs={4}>
              <div style={{ display: 'grid',  justifyContent: 'center' }}>
                <Avatar alt={reservation?.client?.firstName} src={reservation?.client?.facebookProfilePicture} />

                <span >
                  {reservation?.client?.profile?.firstName} {reservation?.client?.profile?.lastName}
                </span>
                <span>
                  {reservation?.client?.profile?.phone}
                </span>
              </div>
            </Grid>
            <Grid item xs={8}>
              <div style={{ display: 'grid', marginBottom: 26 }}>
                <span style={{ flexDirection: 'row', fontWeight: 700 }}>
                  {reservation.ref}
                </span>
                <span style={{ flexDirection: 'row', fontWeight: 700 }}>
                  {reservation.numOfPersons} Personne(s)
                </span>
                <span style={{ flexDirection: 'row', fontWeight: 700 }}>
                  {statusReservation[reservation?.status]}
                </span>

                <span style={{ flexDirection: 'row' }}>
                  {moment(reservation.date).format('DD-MM-YYYY HH:mm')}
                </span>
                <span>
                  <a href={reservation.confirmationUrl}>Lien de confirmation</a>
                </span>
                <span style={{ flexDirection: 'row' }}>{reservation.note}</span>
              </div>
            </Grid>
            <br />
          </Grid>
        </Grid>
      </DialogContent>
    </div>
  );
}
