import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import { makeStyles, styled } from '@material-ui/core/styles';
import { LOADING_STARTED, LOADING_STOPED } from '../../../redux/loader';
import { ADD_CONNECTION_PROPS } from '../../../redux/settings';
import { useStore } from 'react-redux';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import colors from '../../../utils/colors';
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';

function generate(element) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    }),
  );
}
const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));
const useStyles = makeStyles((theme) => ({
  root: {
    height: '70vh',
  },
  image: {
    // backgroundImage: 'url(img/wallpaper2-min.PNG)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    paddingTop: '100px',
  },
  paper: {
    margin: theme.spacing(8, 8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: colors.yellow,
    color: 'white',
    borderRadius: 10,
  },
  cancel: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: colors.white,
    color: colors.medium,
    borderRadius: 10,
  },
  inputText: {
    padding: 10,
    borderRadius: 15,
  },
}));
export function ShowNote({ data, setOpenModal }) {
  const store = useStore();
  const classes = useStyles();
  const [secondary, setSecondary] = React.useState(false);
  const [dense, setDense] = React.useState(false);

  const onSubmit = (value) => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });
    store.dispatch({
      type: ADD_CONNECTION_PROPS,
      payload: {
        connected: true,
      },
    });
    store.dispatch({
      type: LOADING_STOPED,
      payload: {},
    });
  };
  return (
    <div style={{ width: '77vh' }}>
      <DialogTitle style={{ textAlign: 'center' }} id="form-dialog-title">
        <span> Gurin Français</span>
        <HighlightOffIcon
          style={{ cursor: 'pointer', float: 'right' }}
          onClick={() => setOpenModal(false)}
        />
      </DialogTitle>
      <DialogContent>
        <Box sx={{ flexGrow: 1, maxWidth: 752 }}>
          <Grid style={{ maxWidth: '100%' }} item xs={12} md={6}>
            <Demo>
              <List dense={dense}>
                {generate(
                  <ListItem
                    secondaryAction={
                      <IconButton edge="end" aria-label="delete">
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar style={{ backgroundColor: 'green' }}>2</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Single-line item"
                      secondary={secondary ? 'Secondary text' : null}
                    />
                    <IconButton edge="end" aria-label="delete">
                      <DeleteIcon style={{ color: '#f3b021' }} />
                    </IconButton>
                  </ListItem>,
                )}
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>0</Avatar>
                  </ListItemAvatar>
                  <TextField
                    className={classes.inputText}
                    variant="outlined"
                    //onChange={handleChange}
                    margin="normal"
                    fullWidth
                    placeholder="Commentaire (facultatif)"
                    //value={values.first_name}
                    id="first_name"
                    name="first_name"
                    autoComplete="first_name"
                    autoFocus
                  />
                </ListItem>
              </List>
            </Demo>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" className={classes.submit}>
          Valider
        </Button>
      </DialogActions>
    </div>
  );
}
