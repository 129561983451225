import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Dashboard } from '../../pages/dashboard';
import Customers from '../../pages/customers';
import Rp from '../../pages/rp';
import Reservation from '../../pages/reservation';
import Settings from '../../pages/settings';
import { MyLoader } from '../../Components/loaderConnected';
import AppBarAndDrawer from '../../layouts/AppBarAndDrawer/AppBarAndDrawer';
import { withRouter } from 'react-router';
import { useTheme } from '../../theme';
import { SignInFbConfirmation } from '../../pages/signin-fb/confirmation';
import { SignInFbConfirmation2 } from '../../pages/signin-fb/confirmation-2';
import { useLocation } from 'react-router-dom';

const DashboardRoutes = () => {
  const [currentTheme, setCurrentTheme] = useTheme();
  const location = useLocation();
  const reservation_id = new URLSearchParams(location.search).get('reservation');
  return (
    <div>
      <MyLoader />

      <AppBarAndDrawer currentTheme={currentTheme} setCurrentTheme={setCurrentTheme} />
      <Switch>
        <Route
          exact
          path="/dashboard"
          render={(props) => <Dashboard {...props} />}
          key="dashboard"
        />
        <Route
          exact
          path="/customers"
          render={(props) => <Customers {...props} />}
          key="Customers"
        />
        <Route
          exact
          path="/reservation"
          render={(props) => <Reservation {...props} />}
          key="Reservation"
        />
        <Route
          exact
          path="/settings"
          render={(props) => <Settings {...props} />}
          key="Settings"
        />
        <Route exact path="/rp" render={(props) => <Rp {...props} />} key="Rp" />
        <Route
          exact
          //path="/reservation/confirm?reservation=6297689c8ab4e45c0d5c44f2"
          path={`/reservation/confirm`}
          render={(props) => <SignInFbConfirmation {...props} />}
          key="SignInFbConfirmation1"
        />
        <Route
          exact
          path="/confirmation-2"
          render={(props) => <SignInFbConfirmation2 {...props} />}
          key="SignInFbConfirmation2"
        />
        <Redirect exact to="/dashboard" />
      </Switch>
    </div>
  );
};

export default withRouter(DashboardRoutes);
