import React, { useState } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { remove, selectRp, selectLoading } from '../../redux/rp';
import MuiAlert from '@material-ui/lab/Alert';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Toolbar from '@material-ui/core/Toolbar';
import Modal from '../../Components/modals';
import Button from '@material-ui/core/Button';
import Content from '../dashboard/Content';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { SummaryCard } from './containers/Driver';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Avatar from '@material-ui/core/Avatar';
import { FormRp } from './containers/form';
import { SHOW_MODAL_RP } from '../../redux/settings';
import { DeleteRp } from './containers/delete';
import { ShowCustomer } from './containers/show';
import { ShowNote } from './containers/showNote';
import colors from '../../utils/colors';
import PaginatedItems from '../../Components/padination';
import { LOADING_STARTED, LOADING_STOPED } from '../../redux/loader';
import { getRp } from '../../actions/getRp';
import Typography from '@material-ui/core/Typography';
import HeaderPage from '../../Components/HeaderPage';
import { getUser } from '../../actions/getUser';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'avatar',
    numeric: false,
    disablePadding: true,
    label: '#',
  },
  {
    id: 'first_name',
    numeric: false,
    disablePadding: true,
    label: 'Nom',
  },
  {
    id: 'last_name',
    numeric: false,
    disablePadding: true,
    label: 'Prénom',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: true,
    label: 'E-mail',
  },
  {
    id: 'num_whats_app',
    numeric: false,
    disablePadding: true,
    label: 'N° de Whatsapp',
  },
  { id: 'trips', numeric: true, disablePadding: false, label: 'Actions' },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  grow: {
    flexGrow: 1,
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: colors.yellow,
    color: 'white',
    borderRadius: 15,
  },
  icon: {
    color: colors.yellow,
    padding: 5,
    fontSize: 30,
  },
  iconNote: {
    color: colors.yellow,
    fontSize: 15,
  },
  logo: {
    fontSize: 19,
    marginLeft: '1%',
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    color: 'black',
    textDecoration: 'none',
    textTransform: 'capitalize',
  },
}));

export default function Customers() {
  const store = useStore();
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const showModalRp = useSelector((state) => state?.settings?.showModalRp);
  const [selectIndex, setSelectIndex] = useState(null);
  const rows = useSelector(selectRp);
  const loading = useSelector(selectLoading);

  const counts = useSelector((state) => state?.rp?.counts);
  const totalPage = counts / 10;
  const error = false;
  const [snackOpen, setSnackOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalShowCustomer, setOpenModalShowCustomer] = useState(false);
  const [openModalShowNote, setOpenModalShowNote] = useState(false);
  const [dataSet, setDataSet] = useState(null);
  const [rowsCount, setRowsCount] = useState(10);
  if (loading) {
    return (
      <Content>
        <CircularProgress />
      </Content>
    );
  }

  if (error) return `Error! ${error.message}`;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  const snackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackOpen(false);
  };
  const handlePageClick = async (e) => {
    const selectedPage = e.selected;
    setPage(selectedPage);
    setRowsCount(rowsCount + 10);
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });
    await getRp(store, rowsCount + 10);
    store.dispatch({
      type: LOADING_STOPED,
      payload: {},
    });
  };
  return (
    <Content>
      <Snackbar open={snackOpen} autoHideDuration={2000} onClose={snackClose}>
        <Alert onClose={snackClose} severity="success">
          {snackOpen}
        </Alert>
      </Snackbar>
      <div
        style={{
          height: '5vh',
          marginLeft: '1%',
          alignItems: 'flex-end',
          display: 'flex',
        }}
      >
        <HeaderPage />
        <div edge="start" className={classes.grow} />
        <Button
          edge="end"
          className={classes.submit}
          variant="contained"
          onClick={() => {
            setDataSet(null);
            store.dispatch({
              type: SHOW_MODAL_RP,
              payload: {
                showModalRp: true,
              },
            });
          }}
        >
          Ajouter
        </Button>
        <Modal
          body={
            <FormRp
              setDataSet={setDataSet}
              selectIndex={selectIndex}
              data={dataSet}
              setOpenModal={setOpenModal}
            />
          }
          edge="end"
          onSave={() => {
            setSnackOpen('Person added');
          }}
          handleClose={() => {
            setOpenModal(false);
          }}
          open={showModalRp}
        />
        <Modal
          body={<ShowCustomer data={dataSet} setOpenModal={setOpenModalShowCustomer} />}
          edge="end"
          onSave={() => {
            setSnackOpen('Person added');
          }}
          handleClose={() => {
            setOpenModal(false);
          }}
          open={openModalShowCustomer}
        />
        <Modal
          body={
            <DeleteRp
              selectIndex={selectIndex}
              data={dataSet}
              setOpenModal={setOpenModal}
            />
          }
          edge="end"
          onSave={() => {
            setSnackOpen('Person added');
          }}
          handleClose={() => {
            setOpenModal(false);
          }}
          open={openModal}
        />
        <Modal
          body={<ShowNote data={dataSet} setOpenModal={setOpenModalShowNote} />}
          edge="end"
          onSave={() => {
            setSnackOpen('Person added');
          }}
          handleClose={() => {
            setOpenModal(false);
          }}
          open={openModalShowNote}
        />
      </div>
      <div className={classes.root}>
        <SummaryCard
          value={
            <>
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={'small'}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {rows.length > 0 ? (
                      stableSort(rows, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.id);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              /*onClick={(e) => {
                                                          history.push(`/people/${row.id}`);
                                                        }}*/
                              key={`person-${row.id}`}
                              selected={isItemSelected}
                              style={{ cursor: 'pointer' }}
                            >
                              <TableCell align="left">
                                <Avatar alt={row.name} src={row.img} />
                              </TableCell>
                              <TableCell
                                align="left"
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                              >
                                {row.firstName}
                              </TableCell>
                              <TableCell align="left">{row.lastName}</TableCell>
                              <TableCell align="left">{row.email}</TableCell>
                              <TableCell align="left">{row.whatsapp}</TableCell>
                              <TableCell align="left">
                                <VisibilityIcon
                                  onClick={async () => {
                                    store.dispatch({
                                      type: LOADING_STARTED,
                                      payload: {},
                                    });
                                    await getUser(row.id, store);
                                    setOpenModalShowCustomer(true);
                                  }}
                                  className={classes.icon}
                                />
                                <EditIcon
                                  onClick={async () => {
                                    store.dispatch({
                                      type: LOADING_STARTED,
                                      payload: {},
                                    });
                                    setSelectIndex(index);
                                    await getUser(row.id, store);
                                    store.dispatch({
                                      type: SHOW_MODAL_RP,
                                      payload: {
                                        showModalRp: true,
                                      },
                                    });
                                  }}
                                  className={classes.icon}
                                />
                                <DeleteIcon
                                  onClick={() => {
                                    setSelectIndex(index);
                                    setDataSet(row);
                                    setOpenModal(true);
                                  }}
                                  className={classes.icon}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        style={{ cursor: 'pointer' }}
                      >
                        <TableCell align="left">No Data</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {counts > 10 && (
                <PaginatedItems
                  handlePageClick={handlePageClick}
                  items={rows}
                  itemsPerPage={10}
                  totalPage={totalPage}
                />
              )}
            </>
          }
        />
      </div>
    </Content>
  );
}
