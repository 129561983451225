import { apiCall } from '../utils/helpers';
import { GET_LIST_RP } from '../redux/rp';
import { parserDataRp } from '../utils/parser';

export async function getRp(store = {}, rowsCount) {
  try {
    const result = await apiCall({
      method: 'get',
      url: `/users/0/${rowsCount || 10}?role=RP`,
    });
    if (result?.data?.data?.counts > 0) {
      await parserDataRp(result?.data?.data?.users, store, result?.data?.data?.counts);
      /*store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });*/
    } else {
      store.dispatch({
        type: GET_LIST_RP,
        payload: {
          data: [],
        },
      });
      /*store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });*/
    }
  } catch (error) {}
}
