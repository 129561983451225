import { ADD_CONNECTION_PROPS } from '../redux/settings';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { getDashboard } from './getDashboard';
const cookies = new Cookies();
const serverURLAuth = process.env.REACT_APP_PUBLIC_URL_AUTH;
const serverURL = process.env.REACT_APP_PUBLIC_URL;
export async function connect(values, store = {}) {
  const result = await axios.post(`${serverURLAuth}/login`, values);
  if (result?.data?.success) {
    const token = result?.data?.data?.token;
    const refreshToken = result?.data?.data?.refreshToken;
    // const user = result?.data?.data?.user;
    const resultUser = await axios.get(`${serverURL}/users/me`, {
      headers: {
        Authorization: `Bearer ${result?.data?.data?.token}`,
      },
    });
    cookies.set('token', token);
    cookies.set('refreshToken', refreshToken);
    cookies.set('connected', true);
    await getDashboard(store);
    store.dispatch({
      type: ADD_CONNECTION_PROPS,
      payload: {
        connected: true,
        userProfile: resultUser?.data?.data?.user,
        token: token,
      },
    });
    return { success: true, message: 'Login Successfully' };
  } else {
    return result?.data;
  }
}
