import React from 'react';

import DialogTitle from '@material-ui/core/DialogTitle';

import Icon from '../assets/img/circle-xmark-solid.svg';

import PropTypes from 'prop-types';

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <img
          onClick={onClose}
          style={{ width: '3vh', cursor: 'pointer', float: 'right' }}
          src={Icon}
        />
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default BootstrapDialogTitle;
