import toast from '../utils/toast';
import { apiCall } from '../utils/helpers';
import { DELETE_RESERVATION } from '../redux/reservation';

export async function deleteReservation(id, removedIds, store) {
  const result = await apiCall({
    method: 'delete',
    url: `/reservations/${id}`,
  });
  if (result?.data?.success) {
    toast.showSuccess(store, 'Reservation supprimer');
    store.dispatch({
      type: DELETE_RESERVATION,
      payload: {
        removedIds,
      },
    });
    return result?.data;
  } else {
    const message = result?.data?.message
      ? result?.data?.message
      : 'error occured while deleting the card';
    toast.showError(store, message);
    return result?.data;
  }
}
