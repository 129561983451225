import toast from '../utils/toast';
import { apiCall } from '../utils/helpers';
import { ADD_RESERVATION, UPDATE_RESERVATION } from '../redux/reservation';
import moment from 'moment-timezone';

export async function createUpdateReservation(id, values, store = {}, index) {
  const { note, client, date, hours, rp, status, numOfPersons } = values;
  const result = await apiCall({
    method: id ? 'patch' : 'post',
    url: id ? '/reservations/' + id : '/reservations',
    data: id
      ? {
        note,
        status,
        numOfPersons,
        date: moment(`${moment(date).format('YYYY-MM-DD')} ${hours}`)
          .tz('UTC')
          .utc(),
      }
      : {
        note,
        client,
        numOfPersons,
        rp,
        date: moment(`${moment(date).format('YYYY-MM-DD')} ${hours}`)
          .tz('UTC')
          .utc(),
      },
  });
  if (result?.data?.success) {
    if (id)
      store.dispatch({
        type: UPDATE_RESERVATION,
        payload: {
          reservation: result?.data?.data?.reservation,
          index,
        },
      });
    else
      store.dispatch({
        type: ADD_RESERVATION,
        payload: {
          reservation: result?.data?.data?.reservation,
        },
      });
  } else {
    const message = result?.data?.message ? result?.data?.message : 'error occured';
    toast.showError(store, message);
  }
  return result?.data;
}
