import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import colors from '../../utils/colors';

export default function Title(props) {
    return (
        <Typography
            style={{ fontSize: 15, color: colors.black }}
            component="h4"
            variant="h6"
            color="primary"
            gutterBottom
        >
            {props.children}
        </Typography>
    );
}

Title.propTypes = {
    children: PropTypes.node,
};
