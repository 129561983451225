import { apiCall } from '../utils/helpers';
import { GET_LIST_RESERVATION } from '../redux/reservation';

export async function getReservation(store = {}, rowsCount) {
  try {
    const result = await apiCall({
      method: 'get',
      url: `/reservations/0/${rowsCount || 10}`,
    });
    if (result?.data?.data?.counts > 0) {
      store.dispatch({
        type: GET_LIST_RESERVATION,
        payload: {
          data: result?.data?.data?.reservations,
          counts: result?.data?.data?.counts,
        },
      });
    } else {
      store.dispatch({
        type: GET_LIST_RESERVATION,
        payload: {
          data: [],
        },
      });
    }
  } catch (error) {
    console.error('error==========>', error);
  }
}
