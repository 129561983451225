import toast from '../utils/toast';
import { apiCall } from '../utils/helpers';
import { SHOW_SETTINGS_PAGE } from '../redux/settings';

export async function updatePassword(values, store = {}) {
  delete values.confirm_password;
  const result = await apiCall({
    method: 'patch',
    url: `/users/password`,
    data: values,
  });
  if (result?.data?.success) {
    store.dispatch({
      type: SHOW_SETTINGS_PAGE,
      payload: {
        showSettingsPage: 1,
      },
    });
  } else {
    const message = result?.error?.message ? result?.error?.message : 'error occured';
    toast.showError(store, message);
  }
  return result?.data;
}
