import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { useDispatch, useStore } from 'react-redux';
import * as yup from 'yup';
import { LOADING_STARTED, LOADING_STOPED } from '../../redux/loader';
import colors from '../../utils/colors';
import Img from '../../Components/Img';
import Input from '../../Components/Input';
import toast from '../../utils/toast';
import { connect } from '../../actions/connect';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '70vh',
  },
  image: {
    // backgroundImage: 'url(img/wallpaper2-min.PNG)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    paddingTop: '100px',
    justifyContent: 'center'
  },
  paper: {
    margin: theme.spacing(8, 8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: colors.yellow,
    color: 'white',
    borderRadius: 10,
  },
  inputText: {
    borderRadius: 15,
  },
}));

export function SignIn({ loggedIn, logout, login }) {
  const store = useStore();
  const classes = useStyles();
  const loginValidationSchema = yup.object().shape({
    email: yup.string().email('E-mail non valide').required("L'e-mail est requis"),
    password: yup
      .string()
      .min(8, ({ min }) => `au moins 8 caractères`)
      /*.matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        '\n' + 'Mot de passe incorrect',
      )*/
      .required('Mot de passe est requis'),
  });
  const onSubmit = async (values) => {
    try {
      store.dispatch({
        type: LOADING_STARTED,
        payload: {},
      });
      const result = await connect(values, store);

      const message = result?.error ? result?.error?.message : 'Error Eccured';
      if (result?.success) {
        toast.showSuccess(store, result?.message);
        store.dispatch({
          type: LOADING_STOPED,
          payload: {},
        });
      } else {
        toast.showError(store, message);
        store.dispatch({
          type: LOADING_STOPED,
          payload: {},
        });
      }
    } catch (error) {
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
    }
  };
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid container className={classes.image}>
        <Grid
          item
          xs={8}
          component={Paper}
          elevation={8}
          square
          style={{ display: 'flex', borderRadius: 25 }}
        >
          <Grid xs={6} item className={classes.paper}>
            <Formik
              className={classes.form}
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={loginValidationSchema}
              enableReinitialize
              onSubmit={onSubmit}
            >
              {({
                errors,
                touched,
                handleSubmit,
                setFieldValue,
                handleChange,
                values,
              }) => (
                <>
                  <Input
                    handleChange={handleChange}
                    value={values.email}
                    errors={errors}
                    touched={touched}
                    label="Adresse e-mail"
                    type="email"
                    name="email"
                  />
                  <Input
                    handleChange={handleChange}
                    value={values.password}
                    errors={errors}
                    touched={touched}
                    label="Mot de passe"
                    type="password"
                    name="password"
                  />
                  <Grid container>
                    <Grid item xs>
                      <FormControlLabel
                        style={{ color: '#c5c5c5' }}
                        control={
                          <Checkbox
                            style={{ color: '#c5c5c5' }}
                            value="remember"
                            color="primary"
                          />
                        }
                        label="Me souviens"
                      />
                    </Grid>
                  </Grid>{' '}
                  <Grid container>
                    <Grid item xs style={{ textAlign: 'left' }}>
                    </Grid>
                    <Grid item xs style={{ textAlign: 'right' }}>
                      <Link
                        style={{ cursor: 'pointer', color: '#f3b021' }}
                        to="/forgot-password"
                        variant="body2"
                      >
                        Mot de passe oublié?
                      </Link>
                    </Grid>
                  </Grid>
                  <Button
                    variant="contained"
                    className={classes.submit}
                    onClick={handleSubmit}
                  >
                    Se connecter
                  </Button>
                </>
              )}
            </Formik>
          </Grid>
          <Img />
        </Grid>
      </Grid>
    </Grid>
  );
}
