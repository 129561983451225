import React from 'react';
import ReactPaginate from 'react-paginate';
import '../assets/css/paginate.css';

export default function PaginatedItems({ handlePageClick, totalPage }) {
  return (
    <>
      <ReactPaginate
        previousLabel={<span> {'<'} </span>}
        nextLabel={'>'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={totalPage}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
      />
    </>
  );
}
