import React, { useEffect } from 'react';
import AuthRoutes from './auth';
import DashboardRoutes from './dashboard';
import { useSelector, useStore } from 'react-redux';
import { getMe } from '../actions/getMe';

import Cookies from 'universal-cookie';
import { LOADING_STOPED } from '../redux/loader';
const cookies = new Cookies();

const Routes = () => {
  const store = useStore();
  const loading = useSelector((state) => state?.loader?.loading);
  const connected = useSelector((state) => state?.settings?.connected);
  const userProfile = useSelector((state) => state?.settings?.userProfile);

  useEffect(() => {
    const token = cookies.get('token');
    if (loading && !connected && token) {
      getMe(store);
    }else{
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
    }
    return () => {};
  }, [connected, loading, store]);
  let app;

  /*if (loading) app = <MyLoader />;
  else*/ if (!connected && !loading) app = <AuthRoutes />;
  else app = <DashboardRoutes />;
  return <div>{app}</div>;
};

export default Routes;
