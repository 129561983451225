import { createSlice } from '@reduxjs/toolkit';

let nextIdVal = 0;

export function nextID() {
  nextIdVal += 1;
  return nextIdVal;
}

export const rpSlice = createSlice({
  name: 'rp',
  initialState: {
    searchList: [],
    list: [],
    loading: false,
    counts: 0,
  },
  reducers: {
    getSearch: (state, action) => {
      const { data } = action.payload;
      state.searchList = data;
    },
    get: (state, action) => {
      const { data, counts } = action.payload;
      state.list = data;
      state.counts = counts;
    },
    add: (state, action) => {
      const { rp } = action.payload;
      state.list.unshift({
        firstName: rp.profile.firstName,
        lastName: rp.profile.lastName,
        phone: rp.profile.phone,
        picture: rp.profile.picture,
        email: rp.email,
        id: rp._id,
        role: rp.role,
        reviews: rp.reviews,
        whatsapp: rp.profile.whatsapp,
      });
    },
    remove: (state, action) => {
      const { removedIds } = action.payload;
      state.list.splice(removedIds, 1);
    },
    update: (state, action) => {
      const { rp, index } = action.payload;
      state.list[index] = {
        firstName: rp.profile.firstName,
        lastName: rp.profile.lastName,
        phone: rp.profile.phone,
        picture: rp.profile.picture,
        email: rp.email,
        id: rp._id,
        role: rp.role,
        reviews: rp.reviews,
        whatsapp: rp.profile.whatsapp,
      };
    },
  },
});

export const { add, remove, update } = rpSlice.actions;

export const selectRp = (state) => state.rp.list;
export const selectLoading = (state) => state.rp.loading;
export const GET_LIST_RP = 'rp/get';
export const UPDATE_RP = 'rp/update';
export const ADD_RP = 'rp/add';
export const DELETE_RP = 'rp/remove';
export const GET_LIST_SEARCH_RP = 'rp/getSearch';
export default rpSlice.reducer;
