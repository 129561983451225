import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Logo from '../assets/img/logo.png';
import Rec from '../assets/img/Rectangle 46.png';
import colors from '../utils/colors';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    textAlign: 'center',
    color: 'white',
  },
}));

const Img = () => {
  const classes = useStyles();
  return (
    <Grid xs={6} item className={classes.container}>
      <div
        className="img-"
        style={{
          width: '100%',
          height: '100%',
          borderRadius: 20,
        }}
      >
        <div className="centered">
          <span
            style={{
              fontSize: 18,
              fontWeight: 'bold',
              color: colors.black,
            }}
          >
            Bienvenue à
          </span>
          <img src={Logo} />
        </div>
        {/*<img className="centered-img" src={Rec} />*/}
      </div>
    </Grid>
  );
};

export default Img;
