import { GET_LIST_CUSTOMERS } from '../../redux/customers';
import { GET_LIST_RP } from '../../redux/rp';

export async function parserDataCustomer(data, store = {}, counts) {
  try {
    if (data !== []) {
      let newData = [];
      data.map((customer) => {
        newData.push({
          firstName: customer.profile.firstName,
          lastName: customer.profile.lastName,
          phone: customer.profile.phone,
          picture: customer.profile.picture,
          whatsapp: customer.profile.whatsapp,
          rp: customer.rp,
          reviewsAverage: customer.reviewsAverage,
          facebookProfilePicture: customer.facebookProfilePicture,
          email: customer.email,
          id: customer._id,
          role: customer.role,
          reviews: customer.reviews,
        });
      });
      store.dispatch({
        type: GET_LIST_CUSTOMERS,
        payload: {
          data: newData,
          counts,
        },
      });
    } else {
      store.dispatch({
        type: GET_LIST_CUSTOMERS,
        payload: {
          data: [],
        },
      });
    }
  } catch (error) {
    console.log('error in parser ==========>', error);
  }
}
export async function parserDataRp(data, store = {}, counts) {
  try {
    if (data !== []) {
      let newData = [];
      data.map((customer) => {
        newData.push({
          firstName: customer.profile.firstName,
          lastName: customer.profile.lastName,
          phone: customer.profile.phone,
          picture: customer.profile.picture,
          email: customer.email,
          id: customer._id,
          role: customer.role,
          reviews: customer.reviews,
          whatsapp: customer.profile.whatsapp,
        });
      });
      store.dispatch({
        type: GET_LIST_RP,
        payload: {
          data: newData,
          counts,
        },
      });
    } else {
      store.dispatch({
        type: GET_LIST_RP,
        payload: {
          data: [],
        },
      });
    }
  } catch (error) {
    console.log('error in parser ==========>', error);
  }
}

export async function parserLawyers(data) {
  let arrayLawyers = [];
  if (data.length > 0) {
    data.map((item) => {
      arrayLawyers.push({
        value: item.id,
        label: item.title,
      });
    });
  }
  return arrayLawyers;
}
