import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import colors from '../../../utils/colors';
import Avatar from '@material-ui/core/Avatar';
import BootstrapDialogTitle from '../../../Components/BootstrapDialogTitle';
import { useSelector, useStore } from 'react-redux';
import { RESET_USER } from '../../../redux/customers';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '70vh',
  },
  image: {
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    paddingTop: '100px',
  },
  paper: {
    margin: theme.spacing(8, 8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    backgroundColor: '#f5f5f5',
    borderColor: colors.yellow,
    color: colors.yellow,
    borderRadius: 10,
    width: '2vh',
  },
  cancel: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: colors.white,
    color: colors.medium,
    borderRadius: 10,
  },
  inputText: {
    padding: 10,
    borderRadius: 15,
  },
}));
export function ShowCustomer({ data, setOpenModal }) {
  const classes = useStyles();
  const store = useStore();
  const user = useSelector((state) => state?.customers?.user);
  return (
    <div>
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => {
          setOpenModal(false);
          store.dispatch({
            type: RESET_USER,
            payload: {},
          });
        }}
      >
        Détails
      </BootstrapDialogTitle>
      <DialogContent>
        <Grid style={{ alignItems: 'center', marginBottom: 26 }} md={12} container>
          <Grid style={{ alignItems: 'center' }} md={3} container></Grid>
          <Grid style={{ alignItems: 'center' }} md={9} container>
            <Grid item xs={2}>
              <Avatar alt={user.firstName} src={user.facebookProfilePicture} />
            </Grid>
            <br />
            <Grid item xs={6}>
              <div style={{ display: 'grid' }}>
                <span
                  style={{ flexDirection: 'row', fontWeight: 700 }}
                >{`${user.firstName} ${user.lastName}`}</span>
                <span style={{ flexDirection: 'row' }}>{user.email}</span>
                <span style={{ flexDirection: 'row' }}>{user.phone}</span>
              </div>
            </Grid>
            <Grid item xs={2}>
              <Button className={classes.submit}>{user.note || 0}/10</Button>
            </Grid>
            <br />
          </Grid>
        </Grid>
      </DialogContent>
    </div>
  );
}
