import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { SignIn } from '../../pages/signin';
import { ResetPassword } from '../../pages/reset-password';
import { SignInFbConfirmation } from '../../pages/signin-fb/confirmation';
import { SignInFbConfirmation2 } from '../../pages/signin-fb/confirmation-2';
import { MyLoader } from '../../Components/loaderConnected';
import { SignInFbConfirmation3 } from '../../pages/signin-fb/confirmation-3';

const AuthRoutes = () => {
  return (
    <div className="auth">
      <MyLoader />
      <Switch>
        <Route
          exact
          path={`/reservation/confirm`}
          render={(props) => <SignInFbConfirmation {...props} />}
          key="SignInFbConfirmation1"
        />
        <Route
          exact
          path="/confirmation-2"
          render={(props) => <SignInFbConfirmation2 {...props} />}
          key="SignInFbConfirmation2"
        />
        <Route
          exact
          path="/confirmation-3"
          render={(props) => <SignInFbConfirmation3 {...props} />}
          key="SignInFbConfirmation3"
        />
        <Route exact path="/login" render={(props) => <SignIn {...props} />} key="Rp" />
        <Route
          exact
          path="/forgot-password"
          render={(props) => <ResetPassword {...props} />}
          key="ResetPassword"
        />
        <Redirect exact to="/login" />
      </Switch>
    </div>
  );
};

export default AuthRoutes;
