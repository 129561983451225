import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';
import { LOADING_STARTED, LOADING_STOPED } from '../../../redux/loader';
import { useStore } from 'react-redux';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import colors from '../../../utils/colors';
import Avatar from '@material-ui/core/Avatar';
import Icon from '../../../assets/img/circle-xmark-solid.svg';
import { deleteCustomer } from '../../../actions/deleteCustomer';
import { deleteRp } from '../../../actions/deteleRp';
import BootstrapDialogTitle from '../../../Components/BootstrapDialogTitle';
const useStyles = makeStyles((theme) => ({
  root: {
    height: '70vh',
  },
  image: {
    // backgroundImage: 'url(img/wallpaper2-min.PNG)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    paddingTop: '100px',
  },
  paper: {
    margin: theme.spacing(8, 8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: colors.yellow,
    color: 'white',
    borderRadius: 10,
  },
  cancel: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: colors.white,
    color: colors.medium,
    borderRadius: 10,
  },
  inputText: {
    padding: 10,
    borderRadius: 15,
  },
}));
export function DeleteRp({ data, setOpenModal, selectIndex }) {
  const store = useStore();
  const classes = useStyles();

  const onSubmit = async (values) => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });
    try {
      await deleteRp(data.id, selectIndex, store);
      setOpenModal(false);
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
    } catch (error) {
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
    }
  };
  return (
    <div>
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => setOpenModal(false)}
      >
        Voulez-vous supprimer {`${data.firstName} ${data.lastName}`}
      </BootstrapDialogTitle>
      <DialogContent>
        <Grid style={{ alignItems: 'center' }} md={12} container>
          <Grid item xs={7} md={5}></Grid>
          <Grid style={{ marginLeft: '4%' }} item xs={6} md={6}>
            <Avatar alt={data.firstName} src={data.img} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button
          variant="contained"
          className={classes.cancel}
          onClick={() => setOpenModal(false)}
        >
          Annuler
        </Button>
        <Button variant="contained" className={classes.submit} onClick={onSubmit}>
          Supprimer
        </Button>
      </DialogActions>{' '}
    </div>
  );
}
