import { apiCall } from '../utils/helpers';
import { GET_ME } from '../redux/settings';
import { LOADING_STOPED } from '../redux/loader';
import Cookies from 'universal-cookie';
import { getCustomers } from './getCustomer';
import { getRp } from './getRp';
import {getReservation} from "./getReservation";
import {getDashboard} from "./getDashboard";
import {search} from "./searchCustomer";
const cookies = new Cookies();

export async function getMe(store) {
  try {
    const url = `/users/me`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    if (data.success) {
      await getDashboard(store);
      await getCustomers(store);
      await getRp(store);
      await getReservation(store);
      await search(store, 'CLIENT');
      await search(store, 'RP');
      store.dispatch({
        type: GET_ME,
        payload: {
          userProfile: data?.data?.user,
        },
      });
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
    } else {
      cookies.remove('connected');
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
    }
    return data;
  } catch (err) {
    cookies.remove('connected');
    const { response } = err;
    if (response && response.status === 401) {
      await window.localStorage.clear();
    }
    store.dispatch({
      type: LOADING_STOPED,
      payload: {},
    });
  }
  return true;
}
