import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import Spinner from 'react-spinkit';
import { useSelector } from 'react-redux';

export function MyLoader({ active, children }) {
  const loading = useSelector((state) => state?.loader?.loading);
  return (
    <LoadingOverlay
      spinner={
        <Spinner
          className="loading-spinner"
          name="chasing-dots"
          style={{ width: 80, height: 80 }}
        />
      }
      active={loading}
    />
  );
}
