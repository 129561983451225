import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors';
import Typography from '@material-ui/core/Typography';
import Img from '../../Components/Img';
import logo from '../../assets/img/logo.png';
import { isMobile } from 'react-device-detect';
import { useHistory, useParams, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    textAlign: 'center',
    color: 'white',
  },
  root: {
    height: '70vh',
    justifyContent: 'center',
  },
  image: {
    // backgroundImage: 'url(img/wallpaper2-min.PNG)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    paddingTop: '100px',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: colors.white,
    justifyContent: 'center',
    margin: theme.spacing(8, 8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: colors.yellow,
    color: 'white',
    borderRadius: 10,
    height: '7vh',
    width: '33vh',
  },
  inputText: {
    borderRadius: 15,
  },
}));

export function SignInFbConfirmation({ loggedIn, logout, login }) {
  const classes = useStyles();

  let history = useHistory();
  let location = useLocation();
  const reservation_id = new URLSearchParams(location.search).get('reservation');
  function handleClick() {
    history.push(`/confirmation-2?reservation=${reservation_id}`);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      {isMobile ? (
        <div style={{ textAlign: 'center', paddingTop: 80 }}>
          <img src={logo} />
          <Typography
            style={{
              fontFamily: 'revert',
              color: colors.black,
            }}
            component="h2"
            variant="h6"
            color="primary"
            gutterBottom
          >
            Pour confirmer votre réservation il faut se connecter avec votre compte
            Facebook
          </Typography>
          <Button variant="contained" className={classes.submit} onClick={handleClick}>
            Continuer
          </Button>
        </div>
      ) : (
        <Grid container className={classes.image}>
          <Grid
            item
            xs={8}
            component={Paper}
            direction="row"
            elevation={8}
            square
            style={{ display: 'flex', borderRadius: 25 }}
          >
            <Grid xs={6} className={classes.paper}>
              <Typography
                style={{
                  fontFamily: 'revert',
                  textAlign: 'center',
                  width: '40vh',
                  color: colors.black,
                }}
                component="h2"
                variant="h6"
                color="primary"
                gutterBottom
              >
                Pour confirmer votre réservation il faut se connecter avec votre compte
                Facebook
              </Typography>
              <Button
                variant="contained"
                className={classes.submit}
                onClick={handleClick}
              >
                Continuer
              </Button>
            </Grid>
            <Img />
          </Grid>
        </Grid>
      )}
    </div>
  );
}
