import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import colors from '../utils/colors';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  cssLabel: {
    color: '#b4b4b4',
    '&.Mui-focused': {
      color: colors.yellow,
    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: colors.yellow, //focused
    },
  },
  notchedOutline: {},
  cssFocused: {},
  error: {},
  disabled: {},
  root: {
    height: '70vh',
  },
  inputText: {
    padding: 10,
    borderRadius: 15,
  },
}));

const Input = (props) => {
  const { touched, type, rows, errors, handleChange, value, name, label } = props;
  const classes = useStyles();

  return (
    <>
      <TextField
        InputLabelProps={{
          classes: {
            root: errors[name] && touched[name] ? '' : classes.cssLabel,
            focused: classes.cssFocused,
          },
        }}
        InputProps={{
          classes: {
            root: errors[name] && touched[name] ? '' : classes.cssOutlinedInput,
            focused: classes.cssFocused,
            notchedOutline: classes.notchedOutline,
          },
        }}
        inputProps={{
          min: moment().format('YYYY-MM-DD'),
        }}
        type={type}
        className={classes.inputText}
        error={errors[name] && touched[name] ? true : false}
        helperText={touched[name] && errors[name]}
        variant="outlined"
        onChange={handleChange}
        margin="normal"
        fullWidth
        defaultValue={value}
        name={name}
        id={name}
        label={label}
        rows={rows}
        autoComplete={name}
        {...props}
      />
    </>
  );
};

export default Input;
