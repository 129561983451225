import React from 'react';
import { Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import * as yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { LOADING_STARTED, LOADING_STOPED } from '../../../redux/loader';
import { SHOW_MODAL_RP } from '../../../redux/settings';
import { useSelector, useStore } from 'react-redux';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import colors from '../../../utils/colors';
import Input from '../../../Components/Input';
import Icon from '../../../assets/img/circle-xmark-solid.svg';
import toast from '../../../utils/toast';
import { createUpdateRp } from '../../../actions/create-update-rp';
import BootstrapDialogTitle from '../../../Components/BootstrapDialogTitle';
import { RESET_USER } from '../../../redux/customers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '70vh',
  },
  image: {
    // backgroundImage: 'url(img/wallpaper2-min.PNG)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    paddingTop: '100px',
  },
  paper: {
    margin: theme.spacing(8, 8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: colors.yellow,
    color: 'white',
    borderRadius: 10,
  },
  cancel: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: colors.white,
    color: colors.medium,
    borderRadius: 10,
  },
  inputText: {
    padding: 10,
    borderRadius: 15,
  },
}));
export function FormRp({ selectIndex }) {
  const store = useStore();
  const classes = useStyles();
  const user = useSelector((state) => state?.customers?.user);
  const [checked, setChecked] = React.useState(true);
  const loginValidationSchema = yup.object().shape({
    firstName: yup.string().required('Nom est requis'),
    lastName: yup.string().required('Prénom est requis'),
    email: yup.string().email('E-mail non valide'),
    phone: yup
      .string()
      .matches(/(9|7|5|4|3|2)[0-9]{7}/, "N° de whats app n'est pas valide")
      .length(8, 'N° de téléphone doit inclure 5 chiffres')
      .required('N° de téléphone est requis'),
    whatsapp: yup
      .string()
      .matches(/(9|7|5|4|3|2)[0-9]{7}/, "N° de téléphone n'est pas valide")
      .length(8, 'N° de whats app doit inclure 5 chiffres')
      .required('N° de whats app est requis'),
  });
  const onSubmit = async (values) => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });
    try {
      const result = await createUpdateRp(user?.id, values, store, selectIndex);
      if (result.success) {
        store.dispatch({
          type: RESET_USER,
          payload: {},
        });
        toast.showSuccess(store, user?.id ? 'Rp modifié' : 'Rp ajouté');
        store.dispatch({
          type: SHOW_MODAL_RP,
          payload: {
            showModalRp: false,
          },
        });
        store.dispatch({
          type: LOADING_STOPED,
          payload: {},
        });
      } else {
        toast.showError(store, user?.id ? 'Rp non modifié' : 'Rp non ajouté');
        store.dispatch({
          type: LOADING_STOPED,
          payload: {},
        });
      }
    } catch (error) {
      toast.showError(store, user?.id ? 'Rp non modifié' : 'Rp non ajouté');
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
    }
  };
  return (
    <Formik
      className={classes.form}
      initialValues={{
        firstName: user?.firstName ? user?.firstName : '',
        lastName: user?.lastName ? user?.lastName : '',
        email: user?.email ? user?.email : '',
        phone: user?.phone ? user?.phone : '',
        whatsapp: user?.whatsapp ? user.whatsapp : '',
        role: 'RP',
      }}
      validationSchema={loginValidationSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ errors, touched, handleSubmit, setFieldValue, handleChange, values }) => (
        <>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={() => {
              store.dispatch({
                type: RESET_USER,
                payload: {},
              });
              store.dispatch({
                type: SHOW_MODAL_RP,
                payload: {
                  showModalRp: false,
                },
              });
            }}
          >
            {user?.id ? 'Modifier un rp' : 'Ajouter un rp'}
          </BootstrapDialogTitle>
          <DialogContent>
            <Grid md={12} container>
              <Grid item xs={6}>
                <Input
                  handleChange={handleChange}
                  value={values.firstName}
                  errors={errors}
                  touched={touched}
                  label="Nom"
                  type="text"
                  name="firstName"
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  handleChange={handleChange}
                  value={values.lastName}
                  errors={errors}
                  touched={touched}
                  label="Prénom"
                  type="text"
                  name="lastName"
                />
              </Grid>
            </Grid>
            <Input
              handleChange={handleChange}
              value={values.email}
              errors={errors}
              touched={touched}
              label="Adresse e-mail"
              type="email"
              name="email"
            />
            <Grid md={12} container>
              <Grid item xs={6}>
                <Input
                  handleChange={(e) => {
                    handleChange(e);
                    if (checked) {
                      setFieldValue('whatsapp', e.target.value);
                    }
                  }}
                  value={values.phone}
                  errors={errors}
                  touched={touched}
                  label="N° de téléphone"
                  type="text"
                  name="phone"
                />
              </Grid>
              {checked ? (
                ''
              ) : (
                <Grid item xs={6}>
                  <Input
                    handleChange={handleChange}
                    value={values.whatsapp}
                    errors={errors}
                    touched={touched}
                    label="N° de WhatsApp"
                    type="text"
                    name="whatsapp"
                  />
                </Grid>
              )}
            </Grid>
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  onChange={(e) => setChecked(e.target.checked)}
                  defaultChecked={checked}
                />
              }
              label="Le meme numéro WhatsApp"
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              className={classes.cancel}
              onClick={() => {
                store.dispatch({
                  type: SHOW_MODAL_RP,
                  payload: {
                    showModalRp: false,
                  },
                });
                store.dispatch({
                  type: RESET_USER,
                  payload: {},
                });
              }}
            >
              Annuler
            </Button>
            <Button variant="contained" className={classes.submit} onClick={handleSubmit}>
              {user?.id ? 'Enregistrer' : 'Ajouter'}
            </Button>
          </DialogActions>{' '}
        </>
      )}
    </Formik>
  );
}
