import toast from '../utils/toast';
import { apiCall } from '../utils/helpers';
import { ADD_CUSTOMER, UPDATE_CUSTOMER } from '../redux/customers';

export async function createUpdateCustomer(id, values, store = {}, index) {
  if (id) delete values.role;
  if (id) delete values.rp;
  const result = await apiCall({
    method: id ? 'patch' : 'post',
    url: id ? '/users/' + id : '/users',
    data: values,
  });
  if (result?.data?.success) {
    if (id)
      store.dispatch({
        type: UPDATE_CUSTOMER,
        payload: {
          customer: result?.data?.data?.user,
          index,
        },
      });
    else
      store.dispatch({
        type: ADD_CUSTOMER,
        payload: {
          customer: result?.data?.data?.user,
        },
      });
  } else {
    const message = result?.data?.message ? result?.data?.message : 'error occured';
    toast.showError(store, message);
  }
  return result?.data;
}
