import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import { makeStyles, styled } from '@material-ui/core/styles';
import { LOADING_STARTED, LOADING_STOPED } from '../../../redux/loader';
import { SHOW_MODAL_CUSTOMER } from '../../../redux/settings';
import { useSelector, useStore } from 'react-redux';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import colors from '../../../utils/colors';
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/Delete';
import { Formik } from 'formik';
import Input from '../../../Components/Input';
import * as yup from 'yup';
import { createUpdateCustomer } from '../../../actions/create-update-customer';
import toast from '../../../utils/toast';
import BootstrapDialogTitle from '../../../Components/BootstrapDialogTitle';
import { RESET_USER } from '../../../redux/customers';
import moment from 'moment';

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));
const useStyles = makeStyles((theme) => ({
  root: {
    height: '70vh',
  },
  image: {
    // backgroundImage: 'url(img/wallpaper2-min.PNG)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    paddingTop: '100px',
  },
  paper: {
    margin: theme.spacing(8, 8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: colors.yellow,
    color: 'white',
    borderRadius: 10,
  },
  cancel: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: colors.white,
    color: colors.medium,
    borderRadius: 10,
  },
  inputText: {
    padding: 10,
    borderRadius: 15,
  },
}));
export function ShowNote({ data, setOpenModal, selectIndex }) {
  const store = useStore();
  const classes = useStyles();
  const [secondary, setSecondary] = React.useState(false);
  const [dense, setDense] = React.useState(false);
  const user = useSelector((state) => state?.customers?.user);
  const loginValidationSchema = yup.object().shape({
    rating: yup
      .number()
      .typeError('Ne doit être que des chiffres')
      .moreThan(-1, ': Note est entre 0 et 10\n')
      .lessThan(11, ': Note est entre 0 et 10\n'),
    // .matches(/^[0-9]+$/, 'Ne doit être que des chiffres'),
    comment: yup.string(),
  });
  const onSubmit = async (values) => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });
    try {
      let newValue = {};
      if (values.comment && values.rating) {
        newValue.review = {
          rating: values.rating,
          comment: values.comment,
        };
        const result = await createUpdateCustomer(user?.id, newValue, store, selectIndex);
        if (result.success) {
          setOpenModal(null);
          store.dispatch({
            type: SHOW_MODAL_CUSTOMER,
            payload: {
              showModalCustomer: false,
            },
          });
          store.dispatch({
            type: RESET_USER,
            payload: {},
          });
          toast.showSuccess(store, user?.id ? 'Client modifié' : 'Client créé');

          store.dispatch({
            type: LOADING_STOPED,
            payload: {},
          });
        } else {
          toast.showError(store, user?.id ? 'Client non modifié' : 'Client non créé');
          store.dispatch({
            type: LOADING_STOPED,
            payload: {},
          });
        }
      }
    } catch (error) {
      toast.showError(store, user?.id ? 'Client non modifié' : 'Client non créé');
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
    }
  };
  return (
    <div>
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => {
          setOpenModal(false);
          store.dispatch({
            type: RESET_USER,
            payload: {},
          });
        }}
      >
        {`${user?.firstName} ${user?.lastName}`}
      </BootstrapDialogTitle>
      <Formik
        className={classes.form}
        initialValues={{
          rating: '',
          comment: '',
        }}
        validationSchema={loginValidationSchema}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ errors, touched, handleSubmit, setFieldValue, handleChange, values }) => (
          <>
            <DialogContent>
              <Box sx={{ flexGrow: 1, maxWidth: 752 }}>
                <Grid style={{ maxWidth: '100%' }} item xs={12} md={6}>
                  <Demo>
                    <List dense={dense}>
                      {user?.reviews && user?.reviews.length > 0
                        ? user.reviews.map((review) => {
                            return (
                              <ListItem
                                secondaryAction={
                                  <IconButton edge="end" aria-label="delete">
                                    <DeleteIcon />
                                  </IconButton>
                                }
                              >
                                <ListItemAvatar>
                                  <Avatar
                                    style={{
                                      backgroundColor:
                                        parseInt(review.rating) < 5
                                          ? '#FF6200'
                                          : parseInt(review.rating) === 5
                                          ? '#FFA800'
                                          : 'green',
                                    }}
                                  >
                                    {review.rating}
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  primary={review.comment}
                                  secondary={secondary ? 'Secondary text' : null}
                                />
                                <span>
                                  {moment(review.createdAt).format('DD-MM-YYYY HH:mm')}
                                </span>
                                {/*<IconButton edge="end" aria-label="delete">
                                 
                                </IconButton>*/}
                              </ListItem>
                            );
                          })
                        : ''}
                      <ListItem
                        secondaryAction={
                          <IconButton edge="end" aria-label="delete">
                            <DeleteIcon />
                          </IconButton>
                        }
                      >
                        <Grid md={12} container>
                          <Grid item xs={9}>
                            <Input
                              handleChange={handleChange}
                              value={values.comment}
                              errors={errors}
                              touched={touched}
                              label="Commentaire (facultatif)"
                              type="text"
                              name="comment"
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Input
                              handleChange={handleChange}
                              value={values.rating}
                              errors={errors}
                              touched={touched}
                              label="Note/10"
                              type="text"
                              name="rating"
                            />
                          </Grid>
                        </Grid>
                      </ListItem>
                    </List>
                  </Demo>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleSubmit}
                variant="contained"
                className={classes.submit}
              >
                Valider
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </div>
  );
}
