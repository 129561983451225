import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import * as yup from 'yup';
import colors from '../../utils/colors';
import { Link } from 'react-router-dom';
import Img from '../../Components/Img';
import Input from '../../Components/Input';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '70vh',
  },
  image: {
    // backgroundImage: 'url(img/wallpaper2-min.PNG)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    paddingTop: '100px',
    justifyContent: 'center',
  },
  paper: {
    margin: theme.spacing(8, 8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: colors.yellow,
    color: 'white',
    borderRadius: 10,
  },
  inputText: {
    borderColor: 'red',
    borderRadius: 15,
  },
}));

export function GetEmail({ setValue, logout, login }) {
  const classes = useStyles();
  const loginValidationSchema = yup.object().shape({
    email: yup.string().email('E-mail non valide').required("L'e-mail est requis"),
  });
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid container className={classes.image}>
        <Grid
          item
          xs={8}
          component={Paper}
          direction="row"
          elevation={8}
          square
          style={{ display: 'flex', borderRadius: 25 }}
        >
          <Grid xs={6} item className={classes.paper}>
            <Formik
              className={classes.form}
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={loginValidationSchema}
              enableReinitialize
              onSubmit={() => setValue(true)}
            >
              {({
                touched,
                errors,
                handleSubmit,
                setFieldValue,
                handleChange,
                values,
              }) => (
                <>
                  <Input
                    handleChange={handleChange}
                    value={values.email}
                    errors={errors}
                    touched={touched}
                    label="Adresse e-mail"
                    type="email"
                    name="email"
                  />
                  <Grid container>
                    <Grid item xs style={{ textAlign: 'right' }}>
                      <Link
                        style={{ cursor: 'pointer', color: '#f3b021' }}
                        to="/login"
                        variant="body2"
                      >
                        Se connecter
                      </Link>
                    </Grid>
                  </Grid>
                  <Button
                    variant="contained"
                    className={classes.submit}
                    onClick={handleSubmit}
                  >
                    Se connecter
                  </Button>
                </>
              )}
            </Formik>
          </Grid>
          <Img />
        </Grid>
      </Grid>
    </Grid>
  );
}
