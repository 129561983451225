import React from 'react';
import { Provider } from 'react-redux';
import Routes from './routes';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { store } from './redux';
import Toast from './Components/AlertToast';

export default function App() {
  return (
    <Provider store={store}>
      <BrowserRouter basename="/">
        <Routes />
      </BrowserRouter>
      <Toast />
    </Provider>
  );
}
