import React from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Chart from './Chart';
import ChartsCercle from './chartsCercle';
import TotalCard from './TotalCard';
import { drawerWidth } from '../../layouts/AppBarAndDrawer/AppBarAndDrawer';
import { makeStyles } from '@material-ui/core/styles';
import Content from './Content';
import ListAltIcon from '@material-ui/icons/ListAlt';
import HourglassBottomIcon from '@material-ui/icons/HourglassEmptyTwoTone';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import HeaderPage from '../../Components/HeaderPage';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appToolbar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 350,
    borderRadius: 10,
  },
  fixedHeightCharts: {
    height: 500,
    borderRadius: 10,
  },
  balanceCard: {
    height: 100,
    borderRadius: 10,
  },

  logo: {
    marginBottom: '1%',
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    color: 'black',
    textDecoration: 'none',
    textTransform: 'capitalize',
  },
}));

export function Dashboard() {
  const classes = useStyles();
  const fixedHeightCharts = clsx(classes.paper, classes.fixedHeightCharts);
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const balancePaper = clsx(classes.paper, classes.balanceCard);
  const dataDashboard = useSelector((state) => state?.settings?.dataDashboard);
  const pageName = useSelector((state) => state?.settings?.pageName);
  return (
    <Content>
      <div style={{ alignItems: 'flex-end', display: 'flex' }}>
        <HeaderPage />
      </div>
      <Grid container spacing={3}>
        <Grid item xs={3} md={3} lg={3}>
          <Paper className={balancePaper}>
            <TotalCard
              icon={
                <SupervisedUserCircleIcon
                  style={{ color: '#b71b74', fontSize: 65, padding: 8 }}
                />
              }
              name="Nombre de clients"
              total={dataDashboard && dataDashboard.clientsCount}
            />
          </Paper>
        </Grid>
        <Grid item xs={3} md={3} lg={3}>
          <Paper className={balancePaper}>
            <TotalCard
              icon={
                <ListAltIcon style={{ color: '#FFA800', fontSize: 65, padding: 8 }} />
              }
              name="Nombre de RPs"
              total={dataDashboard && dataDashboard.rpsCount}
            />
          </Paper>
        </Grid>
        <Grid item xs={3} md={3} lg={3}>
          <Paper className={balancePaper}>
            <TotalCard
              icon={
                <ListAltIcon style={{ color: '#008AE3', fontSize: 65, padding: 8 }} />
              }
              name="Nombre de reservations"
              total={dataDashboard && dataDashboard.reservationsCount}
            />
          </Paper>
        </Grid>
        <Grid item xs={3} md={3} lg={3}>
          <Paper className={balancePaper}>
            <TotalCard
              icon={
                <HourglassBottomIcon
                  style={{ color: '#FF333E', fontSize: 65, padding: 8 }}
                />
              }
              name="Reservations en attente"
              total={dataDashboard && dataDashboard.pendingReservationsCount}
            />
          </Paper>
        </Grid>
        {/* Recent TotalCard */}
        {/* Chart */}
        <Grid item xs={12} md={8} lg={8}>
          <Paper className={fixedHeightCharts}>
            <Chart
              reservationsByMonth={dataDashboard && dataDashboard.reservationsByMonth}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
          <Paper className={fixedHeightPaper}>
            <ChartsCercle
              confirmedReservationsCount={
                dataDashboard && dataDashboard.confirmedReservationsCount
              }
              reservationsCount={dataDashboard && dataDashboard.reservationsCount}
            />
          </Paper>
        </Grid>
      </Grid>
    </Content>
  );
}
