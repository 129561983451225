import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import colors from '../utils/colors';
import { search } from '../actions/searchCustomer';
import { useStore } from 'react-redux';

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  cssLabel: {
    color: '#b4b4b4',
    '&.Mui-focused': {
      color: colors.yellow,
    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: colors.yellow, //focused
    },
  },
  notchedOutline: {},
  cssFocused: {},
  error: {},
  disabled: {},
  root: {
    height: '70vh',
  },
  inputText: {
    padding: 10,
    borderRadius: 15,
  },
}));
export default function Asynchronous(props) {
  const store = useStore();
  const classes = useStyles();
  const { title, touched, type, rows, errors, handleChange, value, name, label } = props;
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;
  const newRows = [];
  rows.length > 0 &&
    rows.map((row) =>
      newRows.push({
        title: `${row?.profile?.firstName} ${row?.profile?.lastName}${
          row?.reviewsAverage ? ` (${row.reviewsAverage}/10)` : ''
        }`,
        id: row?.id || row?._id,
      }),
    );
  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active) {
        setOptions([...newRows]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      sx={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.title === value.title}
      getOptionLabel={(option) => option.title || title}
      //defaultValue={{ title: title }}
      options={options}
      loading={loading}
      onChange={handleChange}
      {...props}
      renderInput={(params) => (
        <TextField
          InputLabelProps={{
            classes: {
              root: errors[name] && touched[name] ? '' : classes.cssLabel,
              focused: classes.cssFocused,
            },
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
            type: 'search',
            classes: {
              root: errors[name] && touched[name] ? '' : classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline,
            },
          }}
          type={type}
          className={classes.inputText}
          error={errors[name] && touched[name] ? true : false}
          helperText={touched[name] && errors[name]}
          variant="outlined"
          onChange={async (e) => {
            if (name === 'client' || name === 'rp')
              await search(store, 'RP', e.target.value);
          }}
          margin="normal"
          fullWidth
          defaultValue={value}
          name={name}
          id={name}
          label={label}
          autoComplete={name}
          {...params}
        />
      )}
    />
  );
}

// Top films as rated by IMDb users. http://www.imdb.com/chart/top
const topFilms = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
  { title: '12 Angry Men', year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: 'Pulp Fiction', year: 1994 },
  {
    title: 'The Lord of the Rings: The Return of the King',
    year: 2003,
  },
  { title: 'The Good, the Bad and the Ugly', year: 1966 },
  { title: 'Fight Club', year: 1999 },
  {
    title: 'The Lord of the Rings: The Fellowship of the Ring',
    year: 2001,
  },
  {
    title: 'Star Wars: Episode V - The Empire Strikes Back',
    year: 1980,
  },
  { title: 'Forrest Gump', year: 1994 },
  { title: 'Inception', year: 2010 },
  {
    title: 'The Lord of the Rings: The Two Towers',
    year: 2002,
  },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: 'Goodfellas', year: 1990 },
  { title: 'The Matrix', year: 1999 },
  { title: 'Seven Samurai', year: 1954 },
  {
    title: 'Star Wars: Episode IV - A New Hope',
    year: 1977,
  },
  { title: 'City of God', year: 2002 },
  { title: 'Se7en', year: 1995 },
  { title: 'The Silence of the Lambs', year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: 'Life Is Beautiful', year: 1997 },
  { title: 'The Usual Suspects', year: 1995 },
  { title: 'Léon: The Professional', year: 1994 },
  { title: 'Spirited Away', year: 2001 },
  { title: 'Saving Private Ryan', year: 1998 },
  { title: 'Once Upon a Time in the West', year: 1968 },
  { title: 'American History X', year: 1998 },
  { title: 'Interstellar', year: 2014 },
];
/*
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import colors from '../utils/colors';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  cssLabel: {
    color: '#b4b4b4',
    '&.Mui-focused': {
      color: colors.yellow,
    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: colors.yellow, //focused
    },
  },
  notchedOutline: {},
  cssFocused: {},
  error: {},
  disabled: {},
  root: {
    height: '70vh',
  },
  inputText: {
    padding: 10,
    borderRadius: 15,
  },
}));

const Select = (props) => {
  const classes = useStyles();
  const { title, touched, type, rows, errors, handleChange, value, name, label } = props;
  const newRows = [];
  rows.length > 0 &&
    rows.map((row) =>
      newRows.push({
        title: `${row.firstName} ${row.lastName}`,
        id: row?.id || row?._id,
      }),
    );

  const row = newRows.find((item) => item.id === value);
  return (
    <Autocomplete
      id="free-solo-demo"
      freeSolo
      options={newRows}
      getOptionLabel={(option) => option.title}
      onChange={handleChange}
      defaultValue={{
        title: title,
      }}
      renderInput={(params) => (
        <TextField
          InputLabelProps={{
            classes: {
              root: errors[name] && touched[name] ? '' : classes.cssLabel,
              focused: classes.cssFocused,
            },
          }}
          InputProps={{
            type: 'search',
            classes: {
              root: errors[name] && touched[name] ? '' : classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline,
            },
          }}
          type={type}
          className={classes.inputText}
          error={errors[name] && touched[name] ? true : false}
          helperText={touched[name] && errors[name]}
          variant="outlined"
          onChange={handleChange}
          margin="normal"
          fullWidth
          defaultValue={value}
          name={name}
          id={name}
          label={label}
          autoComplete={name}
          {...params}
          //{...props}
        />
      )}
    />
    /!*<TextField
      InputLabelProps={{
        classes: {
          root: errors[name] && touched[name] ? '' : classes.cssLabel,
          focused: classes.cssFocused,
        },
      }}
      InputProps={{
        classes: {
          root: errors[name] && touched[name] ? '' : classes.cssOutlinedInput,
          focused: classes.cssFocused,
          notchedOutline: classes.notchedOutline,
        },
      }}
      {...props}
      type={type}
      className={classes.inputText}
      error={errors[name] ? true : false}
      helperText={errors[name]}
      variant="outlined"
      onChange={handleChange}
      margin="normal"
      fullWidth
      style={{ padding: 10 }}
      value={value}
      id={name}
      label={label}
      name={name}
      autoComplete={name}
    >
      <MenuItem selected key={0} value={null}>
        {label}
      </MenuItem>
      {rows.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {`${option.firstName} ${option.lastName}`}
        </MenuItem>
      ))}
    </TextField>*!/
  );
};

export default Select;
*/
