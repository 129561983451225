import React, { useState } from 'react';
import { GetEmail } from './get-email';
import { SendPassword } from './send-password';

export function ResetPassword() {
    let code = '154875';
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [value, setValue] = useState(false);
    return (
        <>{!value ? <GetEmail setValue={setValue} /> : <SendPassword verificationCode={code} />}</>
    );
}
