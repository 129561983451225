import React, { useEffect, useRef, useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useStore } from 'react-redux';
import { ADD_CONNECTION_PROPS } from '../../redux/settings';
import { LOADING_STARTED, LOADING_STOPED } from '../../redux/loader';
import colors from '../../utils/colors';
import Typography from '@material-ui/core/Typography';
import Img from '../../Components/Img';
import FacebookLogin from 'react-facebook-login';
import { isMobile } from 'react-device-detect';
import logo from '../../assets/img/logo.png';
import { confirmReservation } from '../../actions/confirmReservation';
import toast from '../../utils/toast';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    textAlign: 'center',
    color: 'white',
  },
  root: {
    '&.MuiGrid-root': {
      color: colors.yellow,
    },
    height: '70vh',
  },
  image: {
    // backgroundImage: 'url(img/wallpaper2-min.PNG)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    justifyContent: 'center',
    paddingTop: '100px',
  },
  paper: {
    backgroundColor: colors.white,
    justifyContent: 'center',
    margin: theme.spacing(8, 8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: colors.yellow,
    color: 'white',
    borderRadius: 10,
  },
  inputText: {
    borderRadius: 15,
  },
}));

export function SignInFbConfirmation3() {
  const classes = useStyles();


  return (
    <div className={classes.root}>
      <CssBaseline />
      {isMobile ? (
        <div style={{ textAlign: 'center', paddingTop: 80 }}>
          <img src={logo} />
          <Typography
            style={{
              fontFamily: 'revert',
              textAlign: 'center',
              color: colors.black,
            }}
            component="h2"
            variant="h6"
            color="primary"
            gutterBottom
          >Votre réservation est confirmée avec succés</Typography>
          <img
            src={require('../../assets/img/9004773_tick_check_mark_accept_icon.svg')}
          />
        </div>
      ) : (
        <Grid container className={classes.image}>
          <Grid
            item
            xs={8}
            component={Paper}
            direction="row"
            elevation={8}
            square
            style={{ display: 'flex', borderRadius: 25 }}
          >
            <Grid xs={6} className={classes.paper}>
              <Typography
                style={{
                  fontFamily: 'revert',
                  textAlign: 'center',
                  width: '42vh',
                  color: colors.black,
                }}
                component="h2"
                variant="h6"
                color="primary"
                gutterBottom
              >Votre réservation est confirmée avec succés</Typography>

              <img
                src={require('../../assets/img/9004773_tick_check_mark_accept_icon.svg')}
              />
            </Grid>
            <Img />
          </Grid>
        </Grid>
      )}
    </div>
  );
}
