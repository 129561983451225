import React from 'react';
import decode from 'jwt-decode';
import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const isTokenExpired = (token) => {
  try {
    const decoded = decode(token);
    if (decoded.exp < Date.now() / 1000) {
      // Checking if token is expired
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const apiCall = async (params) => {
  const token = cookies.get('token');
  const refreshToken = cookies.get('refreshToken');
  if (!token || isTokenExpired(token)) {
    // try for a new token

    return axios
      .post(`${process.env.REACT_APP_PUBLIC_URL_AUTH}/refreshToken`, { refreshToken })
      .then(async ({ data }) => {
        const response = data;
        await cookies.set('token', response.token);
        await cookies.set('refreshToken', response.refreshToken);
        const newExtendedParams = {
          ...params,
          url: `${process.env.REACT_APP_PUBLIC_URL + params.url}`,
          headers: {
            ...params.headers,
            Authorization: `Bearer ${response.token}`,
          },
        };
        return newExtendedParams;
      })
      .then((newExtendedParams) => axios(newExtendedParams))
      .catch((apiError) => {
        if (apiError.response && apiError.response.status === 503) {
          cookies.setItem('connected', false);
          document.location.reload();
        }
        return Promise.reject(apiError);
      });
  }
  const extendedParams = {
    ...params,
    url: `${process.env.REACT_APP_PUBLIC_URL + params.url}`,
    headers: {
      ...params.headers,
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(extendedParams).catch((apiError) => {
    if (apiError.response && apiError.response.status === 503) {
      cookies.setItem('connected', false);
      document.location.reload();
    }
    return Promise.reject(apiError);
  });
};

export const statusReservation = {
  PENDING: 'En attente',
  CONFIRMED: 'Confirmé',
  CHECKEDIN: 'Approuvé',
};
