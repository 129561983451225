import React from 'react';
import { Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { LOADING_STARTED, LOADING_STOPED } from '../../../redux/loader';
import { ADD_CONNECTION_PROPS, SHOW_MODAL_CUSTOMER } from '../../../redux/settings';
import { useStore } from 'react-redux';
import colors from '../../../utils/colors';
import Input from '../../../Components/Input';
import { updateProfile } from '../../../actions/update-profile';
import toast from '../../../utils/toast';
import { updatePassword } from '../../../actions/update-password';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '70vh',
  },
  image: {
    // backgroundImage: 'url(img/wallpaper2-min.PNG)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    paddingTop: '100px',
  },
  paper: {
    margin: theme.spacing(8, 8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 2, 2),
    backgroundColor: colors.yellow,
    color: 'white',
    borderRadius: 10,
  },
  cancel: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: colors.white,
    color: colors.medium,
    borderRadius: 10,
  },
  inputText: {
    padding: 10,
    borderRadius: 15,
  },
}));

export function Password({ data, setOpenModal }) {
  const store = useStore();
  const classes = useStyles();
  const loginValidationSchema = yup.object().shape({
    oldPassword: yup
      .string()
      .min(8, ({ min }) => `au moins 8 caractères`)
      .required('Ancien mot de passe est requis'),
    password: yup
      .string()
      .min(8, ({ min }) => `au moins 8 caractères`)
      .required('Mot de passe est requis'),
    confirm_password: yup
      .string()
      .required('Confirmation de mot de passe est requis')
      .when('password', {
        is: (val) => (val && val.length > 0 ? true : false),
        then: yup
          .string()
          .oneOf([yup.ref('password')], 'Les deux mots de passe doivent être identiques'),
      }),
  });
  const onSubmit = async (values) => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });
    try {
      const result = await updatePassword(values, store);
      if (result.success) {
        toast.showSuccess(store, 'Mot de passe modifié');
        store.dispatch({
          type: LOADING_STOPED,
          payload: {},
        });
      } else {
        toast.showError(store, 'Mot de passe non modifié');
        store.dispatch({
          type: LOADING_STOPED,
          payload: {},
        });
      }
    } catch (error) {
      const message = error?.response?.data?.error
        ? 'Le mot de passe actuel que vous avez fourni est incorrect,'
        : 'Mot de passe non modifié';
      toast.showError(store, message);
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
    }
  };
  return (
    <Formik
      className={classes.form}
      initialValues={{
        oldPassword: '',
        password: '',
        confirm_password: '',
      }}
      validationSchema={loginValidationSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ errors, touched, handleSubmit, setFieldValue, handleChange, values }) => (
        <Box>
          <Grid item xs={6} md={12} lg={12}>
            <Grid md={6} container>
              <Input
                handleChange={handleChange}
                value={values.oldPassword}
                errors={errors}
                touched={touched}
                label="Ancien mot de passe"
                type="password"
                name="oldPassword"
              />
            </Grid>
            <Grid md={12} container>
              <Grid item xs={6} md={6} lg={6}>
                <Input
                  handleChange={handleChange}
                  value={values.password}
                  errors={errors}
                  touched={touched}
                  label="Nouveau mot de passe"
                  name="password"
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Input
                  handleChange={handleChange}
                  value={values.confirm_password}
                  errors={errors}
                  touched={touched}
                  label="Confirmer votre nouveau mot de passe"
                  name="confirm_password"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid style={{ textAlign: 'end' }}>
            <Button
              variant="contained"
              className={classes.cancel}
              onClick={() =>
                store.dispatch({
                  type: SHOW_MODAL_CUSTOMER,
                  payload: {
                    showModalCustomer: false,
                  },
                })
              }
            >
              Annuler
            </Button>
            <Button variant="contained" className={classes.submit} onClick={handleSubmit}>
              Ajouter
            </Button>
          </Grid>
        </Box>
      )}
    </Formik>
  );
}
