import { apiCall } from '../utils/helpers';
import { GET_USER } from '../redux/customers';
import { LOADING_STOPED } from '../redux/loader';

export async function getUser(id, store) {
  try {
    const url = `/users/${id}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    if (data.success) {
      store.dispatch({
        type: GET_USER,
        payload: {
          user: data?.data?.user,
        },
      });
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
    } else {
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
    }
    return data;
  } catch (err) {
    store.dispatch({
      type: LOADING_STOPED,
      payload: {},
    });
  }
  return true;
}
