import React from 'react';
import { Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { LOADING_STARTED, LOADING_STOPED } from '../../../redux/loader';
import { SHOW_MODAL_RESERVATION } from '../../../redux/settings';
import { useSelector, useStore } from 'react-redux';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import colors from '../../../utils/colors';
import { selectCustomer } from '../../../redux/customers';
import moment from 'moment';
import Input from '../../../Components/Input';
import SelectComponent from '../../../Components/Select';
import Select from 'react-select'
import Icon from '../../../assets/img/circle-xmark-solid.svg';
import toast from '../../../utils/toast';
import { createUpdateReservation } from '../../../actions/create-update-reservation';
import BootstrapDialogTitle from '../../../Components/BootstrapDialogTitle';
import { RESET_RESERVATION } from '../../../redux/reservation';
import { selectRp } from '../../../redux/rp';
import DatePicker from "react-datepicker";
import styled, { css, createGlobalStyle } from 'styled-components';
import { statusReservation } from '../../../utils/helpers';
const useStyles = makeStyles((theme) => ({
  root: {
    height: '70vh',
  },
  image: {
    // backgroundImage: 'url(img/wallpaper2-min.PNG)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    paddingTop: '100px',
  },
  paper: {
    margin: theme.spacing(8, 8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: colors.yellow,
    color: 'white',
    borderRadius: 10,
  },
  cancel: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: colors.white,
    color: colors.medium,
    borderRadius: 10,
  },
  inputText: {
    padding: 10,
    borderRadius: 15,
  },
  dateTimePicker: {
    width: '94%',
    margin: '0.6em',
    padding: '1.5em',
    borderRadius: 15,
    borderWidth: 1,
    borderColor: '#c4c4c4',
    borderStyle: 'solid',
  }
}));


const statusList = [
  { label: 'En attente', value: 'PENDING' },
  { label: 'Confirmé', value: 'CONFIRMED' },
  { label: 'Approuvé', value: 'CHECKEDIN' },
];



export function FormReservation({ selectIndex }) {
  const store = useStore();
  const classes = useStyles();
  const reservation = useSelector((state) => state?.reservation?.reservation);
  const rows = useSelector((state) => state?.customers?.searchList);
  const rowsRp = useSelector((state) => state?.rp?.searchList);
  const [titleClient, setTitleClient] = React.useState(
    reservation?.client
      ? `${reservation?.client.profile.firstName} ${reservation?.client.profile.lastName}`
      : '',
  );
  const [date, setDate] = React.useState(reservation?.date ? new Date(reservation?.date) : new Date());
  const [time, setTime] = React.useState(reservation?.date ? new Date(reservation?.date) : new Date());
  const [status, setStatus] = React.useState(statusList.filter((item) => item.value === reservation?.status)[0] || null);


  const [titleRp, setTitleRp] = React.useState(
    reservation?.rp
      ? `${reservation?.rp.profile.firstName} ${reservation?.rp.profile.lastName}`
      : '',
  );

  const loginValidationSchema = yup.object().shape({
    //reference: yup.string().required('Référence est requis'),
    // date: yup.string().required('Date est requis'),
    // hours: yup.string().required('Heure est requis'),
    numOfPersons: yup
      .number()
      .typeError('Ne doit être que des chiffres')
      .moreThan(0, ': Note est supérieur à 0\n'),
    note: yup.string(),
    client: yup.string().typeError('Client non valide').required('Client est requis'),
    rp: yup.string().typeError('Rp non valide'),
  });


  const onSubmit = async (values) => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });
    try {
      const data = {
        ...values,
        status: values?.status?.value,
        date,
        hours: moment(time).format('HH:mm'),
      }

      const result = await createUpdateReservation(
        reservation?._id,
        data,
        store,
        selectIndex,
      );
      if (result.success) {
        store.dispatch({
          type: SHOW_MODAL_RESERVATION,
          payload: {
            showModalReservation: false,
          },
        });
        store.dispatch({
          type: RESET_RESERVATION,
          payload: {},
        });
        toast.showSuccess(
          store,
          reservation?._id ? 'Réservation modifié' : 'Réservation ajouté',
        );

        store.dispatch({
          type: LOADING_STOPED,
          payload: {},
        });
      } else {
        toast.showError(
          store,
          reservation?._id ? 'Réservation non modifié' : 'Réservation non ajouté',
        );
        store.dispatch({
          type: LOADING_STOPED,
          payload: {},
        });
      }
    } catch (error) {
      toast.showError(
        store,
        reservation?._id ? 'Réservation non modifié' : 'Réservation non ajouté',
      );
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
    }
  };

  return (
    <Formik
      className={classes.form}
      initialValues={{
        ref: reservation?.ref ? reservation.ref : '',
        status: reservation?.status || '',
        note: reservation?.note ? reservation.note : '',
        numOfPersons: reservation?.numOfPersons ? reservation.numOfPersons : 1,
        client: reservation?.client ? reservation.client._id : '',
        rp: reservation?.rp ? reservation?.rp._id : '',
      }}
      validationSchema={loginValidationSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ errors, touched, handleSubmit, setFieldValue, handleChange, values }) => (
        <div>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={() => {
              store.dispatch({
                type: SHOW_MODAL_RESERVATION,
                payload: {
                  showModalReservation: false,
                },
              });
              store.dispatch({
                type: RESET_RESERVATION,
                payload: {},
              });
            }}
          >
            {reservation?._id ? 'Modifier ' : 'Ajouter '}une réservation
          </BootstrapDialogTitle>
          <DialogContent>
            <Grid style={{ alignItems: 'center' }} md={12} container>
              {reservation?._id ? (
                <Grid item xs={6} md={6} lg={6}>
                  <Input
                    handleChange={handleChange}
                    value={values?.numOfPersons}
                    errors={errors}
                    touched={touched}
                    label="Nombre de personnes"
                    type="text"
                    name="numOfPersons"
                  />
                </Grid>
              ) : (
                ''
              )}

              <Grid item xs={reservation?._id ? 6 : 6}>
                <SelectComponent
                  rows={rows}
                  select
                  value={values.client}
                  name="client"
                  errors={errors}
                  touched={touched}
                  title={titleClient}
                  disabled={reservation?.client}
                  handleChange={(e, option) => {
                    if (option?.title) {
                      setTitleClient(option.title);
                      setFieldValue('client', option.id);
                      const client = rows.find((item) => item._id === option?.id);
                      if (client?._id && client?.rp?._id) {
                        setTitleRp(
                          `${client?.rp?.profile?.firstName} ${client?.rp?.profile?.lastName}`,
                        );
                        setFieldValue('rp', client?.rp?._id);
                      } else setFieldValue('rp', null);
                    }
                  }}
                  label="Client"
                />
              </Grid>
              {!reservation?._id && (
                <Grid item xs={reservation?._id ? 6 : 6}>
                  <SelectComponent
                    rows={rowsRp}
                    select
                    value={values.rp}
                    name="rp"
                    errors={errors}
                    title={titleRp}
                    touched={touched}
                    disabled={reservation?.rp}
                    handleChange={(e, option) => {
                      if (option?.title) {
                        setTitleRp(option.title);
                        setFieldValue('rp', option.id);
                      }
                    }}
                    label="Rp"
                  />
                </Grid>
              )}
            </Grid>
            <Grid md={12} container>

              <Grid item xs={6} md={6} lg={6}>
                <DatePicker
                  className={classes.dateTimePicker}
                  selected={date}
                  onChange={(date) => setDate(date)}
                  minDate={new Date()}
                  dateFormat="MMMM d, yyyy"
                />
              </Grid>
              <Grid item xs={6} md={6} lg={6}>
                <DatePicker
                  className={classes.dateTimePicker}
                  selected={time}
                  onChange={(time) => setTime(time)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                />
              </Grid>
            </Grid>

            <Grid item xs={12} md={12} lg={12} container>
              <Grid item xs={6} md={6} lg={6}>
                <Input
                  handleChange={handleChange}
                  value={values?.numOfPersons}
                  errors={errors}
                  touched={touched}
                  label="Nombre de personnes"
                  type="text"
                  name="numOfPersons"
                />
              </Grid>
              {reservation?._id && (<Grid item xs={6} md={6} lg={6}>
                <Select
                  options={statusList}
                  value={status}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  onChange={(status) => {
                    setFieldValue('status', status);
                    setStatus(status);
                  }}
                />
              </Grid>)}
            </Grid>

            <Grid md={12} container>
              <Grid item xs={12} md={12} lg={12}>
                <Input
                  errors={errors}
                  touched={touched}
                  style={{ padding: 10 }}
                  variant="outlined"
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                  value={values.note}
                  id="note"
                  label="Note"
                  name="note"
                  autoComplete="note"
                  multiline
                  type="text"
                  rows="5"
                  autoFocus={false}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              className={classes.cancel}
              onClick={() => {
                store.dispatch({
                  type: SHOW_MODAL_RESERVATION,
                  payload: {
                    showModalReservation: false,
                  },
                });
                store.dispatch({
                  type: RESET_RESERVATION,
                  payload: {},
                });
              }}
            >
              Annuler
            </Button>
            <Button variant="contained" className={classes.submit} onClick={handleSubmit}>
              {reservation?._id ? 'Enregistrer' : 'Ajouter'}
            </Button>
          </DialogActions>{' '}
        </div>
      )}
    </Formik>
  );
}
