import React from 'react';
import { useSelector } from 'react-redux';
import ToastComponent from './Toast';
function Toast() {
  let toasts = [];
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    toasts = useSelector((state) => state?.toast);
  } catch (error) {
    console.error('error ->', error);
  }
  return (
    <>
      {toasts?.length > 0 &&
        toasts?.map((toast, index) => {
          let item = Object.values(toast)[0];
          return (
            <ToastComponent
              id={item?.id}
              text1={item?.text1}
              text2={item?.text2}
              type={item?.type}
              key={index}
              index={index.toString()}
              visibilityTime={item?.duration}
            />
          );
        })}
    </>
  );
}

export default Toast;
