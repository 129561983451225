import React from 'react';
import Title from './Title';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import RadialSeparators from './Separator';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

export default function ChartsCercle({ reservationsCount, confirmedReservationsCount }) {
  const reservationPercent = (confirmedReservationsCount / reservationsCount) * 100;
  return (
    <React.Fragment>
      <Title>Réservations</Title>
      <CircularProgressbarWithChildren
        strokeWidth={5}
        styles={buildStyles({
          strokeLinecap: 'butt',
          textColor: '#282828',
          trailColor: '#cccccc',
          pathColor: 'green',
          textSize: 12,
          rotation: 12,
        })}
        text={`${(reservationPercent && reservationPercent.toFixed(2)) || 0}%`}
        value={reservationPercent}
      >
        <RadialSeparators
          count={12}
          style={{
            background: '#fff',
            width: '2px',
            // This needs to be equal to props.strokeWidth
            height: `${5}%`,
          }}
        />
      </CircularProgressbarWithChildren>
      <br />
      <Divider />
      <br />
      <Grid xs={12} item container>
        <Grid item xs={6}>
          <Title>Réservations terminées</Title>
        </Grid>
        <Grid style={{ textAlign: 'end' }} item xs={6}>
          <Title>{confirmedReservationsCount}</Title>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
