import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  logo: {
    fontSize: 19,
    marginBottom: '1%',
    fontFamily: 'sans-serif',
    fontWeight: 'bold',
    color: 'black',
    textDecoration: 'none',
  },
}));

export default function HeaderPage() {
  const classes = useStyles();
  const { pathname } = useLocation();
  const pageName = useSelector((state) => state?.settings?.pageName);
  const [page_name, setPageName] = useState(
    pathname === '/dashboard'
      ? 'Dashboard'
      : pathname === '/customers'
      ? 'Liste de clients'
      : pathname === '/rp'
      ? 'Liste de RP’s'
      : pathname === '/reservation'
      ? 'Liste de réservations'
      : 'Paramètres',
  );
  return <span className={classes.logo}>{page_name}</span>;
}
