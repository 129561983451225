export default {
    primary: '#6705a7',
    app: '#a8192b',
    secondary: '#0585a7',
    tertiary: '#AA73E150',
    black: '#000',
    white: '#ffffff',
    gray: '#aaa',
    medium: '#6e6969',
    flatRed: '#EE6E6E',
    dark: '#111',
    darkGray: '#333',
    danger: '#e34949',
    warning: '#e59d4b',
    yellow: '#ffa800',
    ccWhite: '#eee',
    modalBgColor: '#6F54A540',
    borderColor: '#7156A450',
    light: '#e5e2ec',
    success: '#3ecd7e',
    apps: '#6F54A520',
    grey: '#ccc',
    green: '#0b8011',
    red: '#db0211',
    kpibackground: '#341761',
    darkPurple: '#2c2043',
    labelColor: '#998ab6',
    runningColor: '#F2E2E4',
    pausedColor: '#F9E5CE',
    //pausedColor: linear-gradient(270deg,#540d16,#bd233c),
};
