import { createSlice } from '@reduxjs/toolkit';

let nextIdVal = 0;

export function nextID() {
  nextIdVal += 1;
  return nextIdVal;
}

export const customerSlice = createSlice({
  name: 'customer',
  initialState: {
    list: [],
    searchList: [],
    loading: false,
    counts: 0,
    user: {},
  },
  reducers: {
    resetUser: (state, action) => {
      state.user = {};
    },
    getUser: (state, action) => {
      const { user } = action.payload;
      state.user = {
        ...user.profile,
        facebookProfilePicture: user.facebookProfilePicture || null,
        email: user.email,
        id: user._id,
        role: user.role,
        reviews: user.userReviews,
        rp: user.rp,
      };
    },
    get: (state, action) => {
      const { data, counts } = action.payload;
      state.list = data;
      state.counts = counts;
    },
    getSearch: (state, action) => {
      const { data } = action.payload;
      state.searchList = data;
    },
    add: (state, action) => {
      const { customer } = action.payload;
      state.list.unshift({
        firstName: customer.profile.firstName,
        lastName: customer.profile.lastName,
        phone: customer.profile.phone,
        picture: customer.profile.picture,
        email: customer.email,
        id: customer._id,
        role: customer.role,
        reviews: customer.reviews,
        whatsapp: customer.profile.whatsapp,
      });
    },
    remove: (state, action) => {
      const { removedIds } = action.payload;
      state.list.splice(removedIds, 1);
    },
    update: (state, action) => {
      const { customer, index } = action.payload;
      state.list[index] = {
        firstName: customer.profile.firstName,
        lastName: customer.profile.lastName,
        phone: customer.profile.phone,
        picture: customer.profile.picture,
        email: customer.email,
        id: customer._id,
        role: customer.role,
        reviews: customer.reviews,
        reviewsAverage: customer.reviewsAverage,
        whatsapp: customer.profile.whatsapp,
      };
    },
  },
});

export const { add, remove, update } = customerSlice.actions;
export const selectCustomer = (state) => state.customers.list;
export const selectLoading = (state) => state.customers.loading;

export const GET_LIST_CUSTOMERS = 'customer/get';
export const GET_LIST_SEARCH_CUSTOMER = 'customer/getSearch';
export const UPDATE_CUSTOMER = 'customer/update';
export const ADD_CUSTOMER = 'customer/add';
export const DELETE_CUSTOMER = 'customer/remove';
export const RESET_USER = 'customer/resetUser';
export const GET_USER = 'customer/getUser';

export default customerSlice.reducer;
