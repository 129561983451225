import React from 'react';
import { Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { LOADING_STARTED, LOADING_STOPED } from '../../../redux/loader';
import { ADD_CONNECTION_PROPS, SHOW_MODAL_CUSTOMER } from '../../../redux/settings';
import { useStore } from 'react-redux';
import colors from '../../../utils/colors';
import Input from '../../../Components/Input';
import { createUpdateCustomer } from '../../../actions/create-update-customer';
import toast from '../../../utils/toast';
import { updateProfile } from '../../../actions/update-profile';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '70vh',
  },
  image: {
    // backgroundImage: 'url(img/wallpaper2-min.PNG)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    paddingTop: '100px',
  },
  paper: {
    margin: theme.spacing(8, 8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 2, 2),
    backgroundColor: colors.yellow,
    color: 'white',
    borderRadius: 10,
  },
  cancel: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: colors.white,
    color: colors.medium,
    borderRadius: 10,
  },
  inputText: {
    padding: 10,
    borderRadius: 15,
  },
}));

export function Profil({ data, userProfile }) {
  const store = useStore();
  const classes = useStyles();
  const loginValidationSchema = yup.object().shape({
    firstName: yup.string().required('Nom est requis'),
    lastName: yup.string().required('Prénom est requis'),
    email: yup.string().email('E-mail non valide'),
    phone: yup
      .string()
      .matches(/(9|7|5|4|3|2)[0-9]{7}/, "N° de téléphone n'est pas valide")
      .length(8, 'N° de téléphone doit inclure 5 chiffres')
      .required('N° de téléphone est requis'),
    whatsapp: yup
      .string()
      .matches(/(9|7|5|4|3|2)[0-9]{7}/, "N° de whats app n'est pas valide")
      .length(8, 'N° de whats app doit inclure 5 chiffres')
      .required('N° de whats app est requis'),
  });
  const onSubmit = async (values) => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });
    try {
      const result = await updateProfile(userProfile?.id, values, store);
      if (result.success) {
        toast.showSuccess(store, 'Profil modifié');
        store.dispatch({
          type: LOADING_STOPED,
          payload: {},
        });
      } else {
        toast.showError(store, 'Profil non modifié');
        store.dispatch({
          type: LOADING_STOPED,
          payload: {},
        });
      }
    } catch (error) {
      toast.showError(store, 'Profil non modifié');
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
    }
  };
  return (
    <>
      <Formik
        className={classes.form}
        initialValues={{
          firstName: userProfile?.firstName ? userProfile?.firstName : '',
          lastName: userProfile?.lastName ? userProfile?.lastName : '',
          email: userProfile?.email ? userProfile?.email : '',
          whatsapp: userProfile?.whatsapp ? userProfile?.whatsapp : '',
          phone: userProfile?.phone ? userProfile.phone : '',
        }}
        validationSchema={loginValidationSchema}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ errors, touched, handleSubmit, setFieldValue, handleChange, values }) => (
          <>
            <Grid item xs={6}>
              {' '}
              <Grid md={12} item container>
                <Grid item md={6}>
                  <Input
                    handleChange={handleChange}
                    value={values.firstName}
                    errors={errors}
                    touched={touched}
                    label="Nom"
                    name="firstName"
                  />
                </Grid>
                <Grid item md={6}>
                  <Input
                    handleChange={handleChange}
                    value={values.lastName}
                    errors={errors}
                    touched={touched}
                    label="Prénom"
                    name="lastName"
                  />
                </Grid>
              </Grid>
              <Input
                handleChange={handleChange}
                value={values.email}
                errors={errors}
                touched={touched}
                label="Adresse e-mail"
                name="email"
              />
              <Grid item md={12} container>
                <Grid item md={6}>
                  <Input
                    handleChange={handleChange}
                    value={values.phone}
                    errors={errors}
                    touched={touched}
                    label="N° de téléphone"
                    name="phone"
                  />
                </Grid>
                <Grid item md={6}>
                  <Input
                    handleChange={handleChange}
                    value={values.whatsapp}
                    errors={errors}
                    touched={touched}
                    label="N° de WhatsApp"
                    name="whatsapp"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} style={{ textAlign: 'end' }}>
              <Button
                variant="contained"
                className={classes.cancel}
                onClick={() =>
                  store.dispatch({
                    type: SHOW_MODAL_CUSTOMER,
                    payload: {
                      showModalCustomer: false,
                    },
                  })
                }
              >
                Annuler
              </Button>
              <Button
                variant="contained"
                className={classes.submit}
                onClick={handleSubmit}
              >
                Ajouter
              </Button>
            </Grid>
          </>
        )}
      </Formik>
    </>
  );
}
