import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

let nextIdVal = 0;

export function nextID() {
  nextIdVal += 1;
  return nextIdVal;
}

export const reservationSlice = createSlice({
  name: 'reservation',
  initialState: {
    list: [],
    loading: false,
    counts: 0,
    reservation: {},
  },
  reducers: {
    resetReservation: (state, action) => {
      state.reservation = {};
    },
    getReservation: (state, action) => {
      const { reservation } = action.payload;
      state.reservation = reservation;
    },
    get: (state, action) => {
      const { data, counts } = action.payload;
      state.list = data;
      state.counts = counts;
    },
    add: (state, action) => {
      const { reservation } = action.payload;
      state.list.unshift(reservation);
    },
    remove: (state, action) => {
      const { removedIds } = action.payload;
      state.list.splice(removedIds, 1);
    },
    update: (state, action) => {
      const { reservation, index } = action.payload;
      state.list[index] = reservation;
    },
  },
});

export const { add, remove, update } = reservationSlice.actions;

export const selectReservation = (state) => state.reservation.list;
export const selectLoading = (state) => state.reservation.loading;
export const GET_LIST_RESERVATION = 'reservation/get';
export const UPDATE_RESERVATION = 'reservation/update';
export const ADD_RESERVATION = 'reservation/add';
export const DELETE_RESERVATION = 'reservation/remove';
export const RESET_RESERVATION = 'reservation/resetReservation';
export const GET_RESERVATION = 'reservation/getReservation';
export default reservationSlice.reducer;
