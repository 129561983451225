import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import { useHistory } from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';

import colors from '../../utils/colors';
import IconLogOut from '../../assets/img/_icons.svg';
import IconReservation from '../../assets/img/task.svg';
import IconSettings from '../../assets/img/settings.svg';
import IconUsers from '../../assets/img/user.svg';
import IconDashboard from '../../assets/img/dashboard.svg';
import IconLogo from '../../assets/img/logo.png';

import {
  SET_PAGE_NAME,
  SHOW_MODAL_CUSTOMER,
  SHOW_MODAL_RESERVATION,
  SHOW_MODAL_RP,
  SHOW_SETTINGS_PAGE,
} from '../../redux/settings';

import { useSelector, useStore } from 'react-redux';
import { Menu, MenuItem } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { getCustomers } from '../../actions/getCustomer';
import { getRp } from '../../actions/getRp';
import { logout } from '../../actions/logout';
import toast from '../../utils/toast';
import { LOADING_STARTED, LOADING_STOPED } from '../../redux/loader';
import { getReservation } from '../../actions/getReservation';
import { getDashboard } from '../../actions/getDashboard';
import { search } from '../../actions/searchCustomer';
export const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  logo: {
    color: 'black',
    textDecoration: 'none',
    textTransform: 'capitalize',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      backgroundColor: colors.white,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  active: {
    backgroundColor: theme.palette.action.selected,
  },
}));

function ResponsiveDrawer(props) {
  const store = useStore();
  let history = useHistory();
  const { container, setCurrentTheme, currentTheme } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { pathname } = useLocation();
  const isHome = false; // pathname === "/";
  const [mobileOpen, setMobileOpen] = useState(false);

  try {
    /*store.dispatch({
      type: SET_PAGE_NAME,
      payload: {
        pageName: pageName,
      },
    });*/
  } catch (e) {}
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const userProfile = useSelector((state) => state?.settings?.userProfile);
  const [openSettings, setOpenSettings] = useState(true);
  const [open, setOpen] = useState(true);
  const [openRp, setOpenRp] = useState(true);
  const [itemName, setItemName] = useState('PF');
  const [openReservation, setOpenReservation] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const openAvatar = Boolean(anchorEl);
  const handleClickAvatar = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = async () => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });
    store.dispatch({
      type: SET_PAGE_NAME,
      payload: {
        pageName: 'Liste de clients',
      },
    });
    await search(store, 'RP');
    await getCustomers(store);
    store.dispatch({
      type: LOADING_STOPED,
      payload: {},
    });
    /*setOpen(!open);
    setOpenRp(false);
    setOpenSettings(false);
    setOpenReservation(false);*/
  };
  const handleClickRp = async () => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });
    store.dispatch({
      type: SET_PAGE_NAME,
      payload: {
        pageName: 'Liste de RP’s',
      },
    });
    await getRp(store);
    store.dispatch({
      type: LOADING_STOPED,
      payload: {},
    });
    /*setOpenRp(!openRp);
    setOpen(false);
    setOpenReservation(false);
    setOpenSettings(false);*/
  };
  const handleClickReservation = async () => {
    store.dispatch({
      type: SET_PAGE_NAME,
      payload: {
        pageName: 'Liste de réservations',
      },
    });
    await getReservation(store);
    await search(store, 'CLIENT');
    await search(store, 'RP');
    /*setOpenRp(false);
    setOpen(false);
    setOpenReservation(!openReservation);
    setOpenSettings(false);*/
  };
  const handleClickSettings = async () => {
    ///await getMe(store);
    store.dispatch({
      type: SET_PAGE_NAME,
      payload: {
        pageName: 'Paramètres',
      },
    });
    /*setOpenRp(false);
    setOpen(false);
    setOpenReservation(false);
    setOpenSettings(!openSettings);*/
  };

  const disconnect = async () => {
    try {
      await logout(store);
    } catch (error) {
      toast.showError(store, 'Logout not found');
    }
  };
  /* Modifying the source code from the template example to use the react router pathname hook to set
  selected prop and to use the react router component prop */

  const drawer = (
    <div>
      <div
        style={{
          paddingTop: '1vh',
          paddingLeft: '2vh',
        }}
        className={classes.toolbar}
      >
        <img
          onClick={async () => {
            await getDashboard(store);
            history.push('/dashboard');
          }}
          style={{
            width: '9vh',
            cursor: 'pointer',
          }}
          src={IconLogo}
        />
      </div>
      <Divider />
      <List>
        <ListItem
          component={RouterLink}
          selected={pathname === `/dashboard`}
          to={`/dashboard`}
          button
          key={'dashboard'}
          onClick={async () => {
            store.dispatch({
              type: SET_PAGE_NAME,
              payload: {
                pageName: 'Dashboard',
              },
            });
            await getDashboard(store);
          }}
        >
          <ListItemIcon>
            <img src={IconDashboard} />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <img src={IconUsers} />
          </ListItemIcon>
          <ListItemText primary="Clients" />
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              component={RouterLink}
              selected={pathname === `/customers`}
              to={`/customers`}
              button
              key={'customers'}
              sx={{ pl: 4 }}
              onClick={handleClick}
            >
              <ListItemIcon></ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    type="body2"
                    style={{
                      fontWeight: pathname === `/customers` ? 500 : 400,
                    }}
                  >
                    Liste
                  </Typography>
                }
              />
            </ListItem>
            <ListItem
              //selected={pathname === `/${text}`}
              button
              key={'Liste'}
              onClick={() => {
                history.push(`/customers`);
                store.dispatch({
                  type: SHOW_MODAL_CUSTOMER,
                  payload: {
                    showModalCustomer: true,
                    dataSelectedCustomer: null,
                  },
                });
              }}
              sx={{ pl: 4 }}
            >
              <ListItemIcon></ListItemIcon>
              <ListItemText primary="Ajouter" />
            </ListItem>
          </List>
        </Collapse>
        <ListItem>
          <ListItemIcon>
            <img src={IconUsers} />
          </ListItemIcon>
          <ListItemText primary="Liste de Rp's" />
        </ListItem>
        <Collapse in={openRp} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              component={RouterLink}
              selected={pathname === `/rp`}
              to={`/rp`}
              button
              key={'rp'}
              onClick={handleClickRp}
              sx={{ pl: 4 }}
            >
              <ListItemIcon></ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    type="body2"
                    style={{
                      fontWeight: pathname === `/rp` ? 500 : 400,
                    }}
                  >
                    Liste
                  </Typography>
                }
              />
            </ListItem>
            <ListItem
              //selected={pathname === `/${text}`}
              button
              key={'Liste'}
              onClick={async () => {
                store.dispatch({
                  type: SHOW_MODAL_RP,
                  payload: {
                    showModalRp: true,
                  },
                });
                history.push(`/rp`);
              }}
              sx={{ pl: 4 }}
            >
              <ListItemIcon></ListItemIcon>
              <ListItemText primary="Ajouter" />
            </ListItem>
          </List>
        </Collapse>
        <ListItem>
          <ListItemIcon>
            <img src={IconReservation} />
          </ListItemIcon>
          <ListItemText primary="Réservation" />
        </ListItem>
        <Collapse in={openReservation} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              component={RouterLink}
              selected={pathname === `/reservation`}
              to={`/reservation`}
              button
              key={'reservation'}
              sx={{ pl: 4 }}
              onClick={handleClickReservation}
            >
              <ListItemIcon></ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    type="body2"
                    style={{
                      fontWeight: pathname === `/reservation` ? 500 : 400,
                    }}
                  >
                    Liste
                  </Typography>
                }
              />
            </ListItem>
            <ListItem
              //selected={pathname === `/${text}`}
              button
              key={'Liste'}
              onClick={() => {
                history.push(`/reservation`);
                store.dispatch({
                  type: SHOW_MODAL_RESERVATION,
                  payload: {
                    showModalReservation: true,
                  },
                });
              }}
              sx={{ pl: 4 }}
            >
              <ListItemIcon></ListItemIcon>
              <ListItemText primary="Ajouter" />
            </ListItem>
          </List>
        </Collapse>
        <ListItem>
          <ListItemIcon>
            <img src={IconSettings} />
          </ListItemIcon>
          <ListItemText primary="Paramètres" />
        </ListItem>
        <Collapse in={openSettings} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              component={RouterLink}
              selected={pathname === `/settings` && itemName === 'PF'}
              to={`/settings`}
              button
              key={'settings'}
              onClick={() => {
                setItemName('PF');
                if (pathname === `/settings`)
                  store.dispatch({
                    type: SHOW_SETTINGS_PAGE,
                    payload: {
                      showSettingsPage: 1,
                    },
                  });
              }}
              sx={{ pl: 4 }}
            >
              <ListItemIcon></ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    type="body2"
                    style={{
                      fontWeight:
                        pathname === `/settings` && itemName === 'PF' ? 500 : 400,
                    }}
                  >
                    Profil
                  </Typography>
                }
              />
            </ListItem>
            <ListItem
              selected={pathname === `/settings` && itemName === 'MP'}
              button
              key={'Liste'}
              onClick={() => {
                setItemName('MP');
                history.push(`/settings`);
                handleClickSettings();
                store.dispatch({
                  type: SHOW_SETTINGS_PAGE,
                  payload: {
                    showSettingsPage: 2,
                  },
                });
              }}
              sx={{ pl: 4 }}
            >
              <ListItemIcon></ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    type="body2"
                    style={{
                      fontWeight:
                        pathname === `/settings` && itemName === 'MP' ? 500 : 400,
                    }}
                  >
                    Mot de passe
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Collapse>
      </List>
      <Divider />
    </div>
  );
  function goToSettings(value) {
    history.push('/settings');
    store.dispatch({
      type: SHOW_SETTINGS_PAGE,
      payload: {
        showSettingsPage: value,
      },
    });
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <div
        style={{
          height: '64px',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          filter: 'contrast(75%)',
          backgroundImage: 'url(/img/wallpaper.jpeg)',
          position: 'absolute',
          top: '0px',
          width: '100%',
          zIndex: -2,
        }}
      />
      <AppBar position="sticky" className={isHome ? 's' : classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap className={classes.logo}>
            {/*{pageName}*/}
          </Typography>
          <div style={{ flexGrow: 1 }}></div>
          <Grid
            onClick={handleClickAvatar}
            style={{ cursor: 'pointer', display: 'contents' }}
          >
            <IconButton
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <Avatar sx={{ width: 32, height: 32 }}>
                {' '}
                <img src={userProfile.picture} />
              </Avatar>
            </IconButton>
            <div style={{ height: '7vh', padding: '5px' }}>
              <Typography
                style={{
                  fontFamily: 'revert',
                  textAlign: 'left',
                  fontSize: 13,
                  color: colors.black,
                }}
                component="h2"
                variant="h6"
                color="primary"
                gutterBottom
              >
                {`${userProfile.firstName || ''} ${userProfile.lastName || ''}`}
              </Typography>
              <Typography
                style={{
                  fontFamily: 'revert',
                  textAlign: 'left',
                  fontSize: 13,
                  color: colors.medium,
                }}
                component="h2"
                variant="h6"
                color="primary"
                gutterBottom
              >
                {userProfile.role}
              </Typography>
            </div>
          </Grid>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={openAvatar}
            onClose={handleClose}
            onClick={handleClose}
            style={{
              right: 0,
              top: 30,
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={() => goToSettings(1)}>Profil</MenuItem>
            <MenuItem onClick={() => goToSettings(2)}>Mot de passe</MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                disconnect();
                //  document.location.reload();
              }}
            >
              <img style={{ paddingRight: '5px' }} src={IconLogOut} />
              Déconnexion
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      {isHome && !mobileOpen ? (
        <div />
      ) : (
        <nav aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open={isHome}
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
      )}
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
};

export default ResponsiveDrawer;
