import toast from '../utils/toast';
import { apiCall } from '../utils/helpers';
import { EDIT_PROFILE } from '../redux/settings';

export async function updateProfile(id, values, store = {}) {
  const result = await apiCall({
    method: 'patch',
    url: `/users/${id}`,
    data: values,
  });
  if (result?.data?.success) {
    if (id)
      store.dispatch({
        type: EDIT_PROFILE,
        payload: {
          userProfile: result?.data?.data.user,
        },
      });
  } else {
    const message = result?.data?.message ? result?.data?.message : 'error occured';
    toast.showError(store, message);
  }
  return result?.data;
}
