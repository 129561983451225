import { createSlice } from '@reduxjs/toolkit';

export const loaderSlice = createSlice({
  name: 'loader',
  initialState: {
    loading: true,
  },
  reducers: {
    loadingStarted: (state, action) => {
      state.loading = true;
    },
    loadingStopped: (state, action) => {
      state.loading = false;
    },
  },
});

export const LOADING_STARTED = 'loader/loadingStarted';
export const LOADING_STOPED = 'loader/loadingStopped';

export default loaderSlice.reducer;
