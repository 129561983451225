import { createSlice } from '@reduxjs/toolkit';

export const toastSlice = createSlice({
    name: 'toast',
    initialState: [],
    reducers: {
        toastAdded: (dataSet, action) => {
            const id = action?.payload?.id;
            let obj = {};

            obj[id] = action?.payload?.toast;

            dataSet.unshift(obj);

            if (dataSet.length > 3) dataSet.length = 3;
        },
        toastDeleted: (dataSet, action) => {
            const id = action?.payload?.id;
            let index = -1;
            for (var i = 0; i < dataSet.length; i++) {
                if (Object.values(dataSet[i])[0]?.id == id) {
                    index = i;
                }
            }
            if (index > -1) {
                delete dataSet[index];
                dataSet.splice(index, 1);
            }
        },
        reset: (dataSet, action) => {
            return (dataSet = []);
        },
    },
});
export const TOAST_ADD = 'toast/toastAdded';
export const TOAST_DELETE = 'toast/toastDeleted';
export default toastSlice.reducer;
