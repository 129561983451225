import { apiCall } from '../utils/helpers';
import { GET_DATA_DASHBOARD } from '../redux/settings';

export async function getDashboard(store = {}) {
  try {
    const result = await apiCall({
      method: 'get',
      url: `/stats`,
    });

    if (result?.data?.success) {
      store.dispatch({
        type: GET_DATA_DASHBOARD,
        payload: {
          dataDashboard: result?.data?.data,
        },
      });
    } else {
      store.dispatch({
        type: GET_DATA_DASHBOARD,
        payload: {
          dataDashboard: {},
        },
      });
    }
  } catch (error) {
    console.error('error==========>', error);
  }
}
