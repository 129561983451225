import { createSlice } from '@reduxjs/toolkit';

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    showSettingsPage: 1,
    showModalCustomer: false,
    showModalRp: false,
    showModalReservation: false,
    dataSelectedCustomer: null,
    pageName: 'Dashboard',
    connected: false,
    userProfile: {},
    dataDashboard: {},
    token: null,
  },
  reducers: {
    reset: (dataSet, action) => {
      return (dataSet = {
        connected: false,
        userProfile: {},
        token: null,
      });
    },
    getProfile: (dataSet, action) => {
      const { userProfile } = action.payload;
      dataSet['connected'] = true;
      dataSet['userProfile'] = {
        ...userProfile.profile,
        email: userProfile.email,
        id: userProfile._id,
        role: userProfile.role,
      };
    },

    getDataDashboard: (dataSet, action) => {
      const { dataDashboard } = action.payload;
      dataSet['dataDashboard'] = dataDashboard;
    },
    editProfile: (dataSet, action) => {
      const { userProfile } = action.payload;
      dataSet['userProfile'] = {
        id: userProfile._id,
        ...userProfile.profile,
        ...userProfile,
      };
    },
    addDataConnection: (dataSet, action) => {
      const { connected, userProfile, token } = action.payload;
      dataSet['connected'] = connected;
      dataSet['userProfile'] = {
        ...userProfile.profile,
        email: userProfile.email,
        id: userProfile._id,
        role: userProfile.role,
      };
      dataSet['token'] = token;
    },
    setConnected: (dataSet, action) => {
      const { connected } = action.payload;
      dataSet['connected'] = connected;
    },
    setPageName: (dataSet, action) => {
      const { pageName } = action.payload;
      dataSet['pageName'] = pageName;
    },
    refreshToken: (dataSet, action) => {
      const { token } = action.payload;
      dataSet.token = token;
    },
    showModalCustomer: (dataSet, action) => {
      const { showModalCustomer, dataSelectedCustomer } = action.payload;
      dataSet.showModalCustomer = showModalCustomer;
      dataSet.dataSelectedCustomer = dataSelectedCustomer;
    },
    showModalRP: (dataSet, action) => {
      const { showModalRp } = action.payload;
      dataSet.showModalRp = showModalRp;
    },
    showModalReservation: (dataSet, action) => {
      const { showModalReservation } = action.payload;
      dataSet.showModalReservation = showModalReservation;
    },
    showSettingsPage: (dataSet, action) => {
      const { showSettingsPage } = action.payload;
      dataSet.showSettingsPage = showSettingsPage;
    },
  },
});
export const { addDataConnection, setConnected } = settingsSlice.actions;

export const SET_PAGE_NAME = 'settings/setPageName';
export const RESET_SETTINGS = 'settings/reset';
export const ADD_CONNECTION_PROPS = 'settings/addDataConnection';
export const SHOW_MODAL_CUSTOMER = 'settings/showModalCustomer';
export const SHOW_MODAL_RP = 'settings/showModalRP';
export const SHOW_MODAL_RESERVATION = 'settings/showModalReservation';
export const SHOW_SETTINGS_PAGE = 'settings/showSettingsPage';
export const GET_ME = 'settings/getProfile';
export const GET_DATA_DASHBOARD = 'settings/getDataDashboard';
export const EDIT_PROFILE = 'settings/editProfile';

export default settingsSlice.reducer;
