import { RESET_SETTINGS } from '../redux/settings';
import Cookies from 'universal-cookie';
import axios from 'axios';
const cookies = new Cookies();
const serverURL = process.env.REACT_APP_PUBLIC_URL_AUTH;
export async function logout(store = {}) {
  const token = cookies.get('token');
  const result = await axios.get(`${serverURL}/logout`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (result?.data?.success) {
    cookies.remove('token');
    cookies.remove('refreshToken');
    cookies.remove('connected');
    store.dispatch({
      type: RESET_SETTINGS,
      payload: {},
    });
    return { success: true, message: 'LogOut Successfully' };
  } else {
    return result?.data;
  }
}
