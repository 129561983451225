import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import colors from '../../utils/colors';
import Img from '../../Components/Img';
import Input from '../../Components/Input';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '70vh',
  },
  image: {
    // backgroundImage: 'url(img/wallpaper2-min.PNG)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    paddingTop: '100px',
    justifyContent: 'center',
  },
  paper: {
    margin: theme.spacing(8, 8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: colors.yellow,
    color: 'white',
    borderRadius: 10,
  },
  inputText: {
    borderRadius: 15,
    fontFamily: 'Oxanium',
  },
}));

export function SendPassword({ loggedIn, verificationCode, login }) {
  const classes = useStyles();
  const loginValidationSchema = yup.object().shape({
    code: yup
      .string()
      .required('Code de verification est requis')
      .when(verificationCode, {
        is: (val) => (val && val.length > 0 ? true : false),
        then: yup
          .string()
          .oneOf([verificationCode], 'Les deux mots de passe doivent être identiques'),
      }),
    password: yup
      .string()
      .min(8, ({ min }) => `au moins 8 caractères`)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        '\n' + 'Mot de passe incorrect',
      )
      .required('Mot de passe est requis'),
    confirm_password: yup
      .string()
      .required('Confirmation de mot de passe est requis')
      .when('password', {
        is: (val) => (val && val.length > 0 ? true : false),
        then: yup
          .string()
          .oneOf([yup.ref('password')], 'Les deux mots de passe doivent être identiques'),
      }),
  });
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid container className={classes.image}>
        <Grid
          item
          xs={8}
          component={Paper}
          direction="row"
          elevation={8}
          square
          style={{ display: 'flex', borderRadius: 25 }}
        >
          <Grid xs={6} item className={classes.paper}>
            <Formik
              className={classes.form}
              initialValues={{
                code: '',
                password: '',
                confirm_password: '',
              }}
              validationSchema={loginValidationSchema}
              enableReinitialize
              onSubmit={login}
            >
              {({
                errors,
                touched,
                handleSubmit,
                setFieldValue,
                handleChange,
                values,
              }) => (
                <>
                  <Input
                    handleChange={handleChange}
                    value={values.code}
                    errors={errors}
                    touched={touched}
                    label="Code"
                    type="text"
                    name="code"
                  />
                  <Input
                    handleChange={handleChange}
                    value={values.password}
                    errors={errors}
                    touched={touched}
                    label="Mot de passe"
                    type="password"
                    name="password"
                  />
                  <Input
                    handleChange={handleChange}
                    value={values.confirm_password}
                    errors={errors}
                    touched={touched}
                    label="Confirmer mot de passe"
                    type="password"
                    name="confirm_password"
                  />
                  <Grid container>
                    <Grid item xs>
                      <Link style={{ color: '#d0d0d0' }} to="#" variant="body2">
                        Renvoyer le code
                      </Link>
                    </Grid>
                  </Grid>{' '}
                  <Grid container>
                    <Grid item xs style={{ textAlign: 'right' }}>
                      <Link style={{ color: '#f3b021' }} to="/login" variant="body2">
                        Se connecter
                      </Link>
                    </Grid>
                  </Grid>
                  <Button
                    variant="contained"
                    className={classes.submit}
                    onClick={handleSubmit}
                  >
                    Se connecter
                  </Button>
                </>
              )}
            </Formik>
          </Grid>
          <Img />
        </Grid>
      </Grid>
    </Grid>
  );
}
