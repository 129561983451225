import { TOAST_ADD, TOAST_DELETE } from '../../redux/toast';

const generateUniqueKey = (prefix) => {
  var uniqueKey = null;
  prefix = prefix.length !== 0 ? prefix : '';
  uniqueKey = `_${prefix}_${new Date().getTime()}_${Math.random()}`;
  return uniqueKey;
};

const showWarning = (store, message, duration = 5000) => {
  let id = generateUniqueKey('toast');
  try {
    if (typeof message === 'string')
      store.dispatch({
        type: TOAST_ADD,
        payload: {
          id,
          toast: {
            id,
            text1: 'Attention',
            text2: message,
            type: 'warning',
            duration,
          },
        },
      });
    else throw new Error('The parameter message supplied to the toast is not string');
  } catch (error) {
    // propper handeling required, with appLogger.error(error); OR errorsHandler(error);
    console.error('error ->', error);
  }
};
const showSuccess = (store, message, duration = 5000) => {
  let id = generateUniqueKey('toast');
  try {
    if (typeof message === 'string')
      store.dispatch({
        type: TOAST_ADD,
        payload: {
          id,
          toast: {
            id,
            text1: 'Succé',
            text2: message,
            type: 'success',
            duration,
          },
        },
      });
    else throw new Error('The parameter message supplied to the toast is not string');
  } catch (error) {
    // propper handeling required, with appLogger.error(error); OR errorsHandler(error);
    console.error('error ->', error);
  }
};
const showError = (store, message) => {
  let id = generateUniqueKey('toast');
  try {
    if (typeof message === 'string') {
      store.dispatch({
        type: TOAST_ADD,
        payload: {
          id,
          toast: {
            id,
            text1: 'Erruer',
            text2: message,
            type: 'error',
          },
        },
      });
    } else throw new Error('The parameter message supplied to the toast is not string');
  } catch (error) {
    // propper handeling required, with appLogger.error(error); OR errorsHandler(error);
    console.error('error ->', error);
  }
};
const showInfo = (store, message, duration = 5000) => {
  let id = generateUniqueKey('toast');
  try {
    if (typeof message === 'string')
      store.dispatch({
        type: TOAST_ADD,
        payload: {
          id,
          toast: {
            id,
            text1: 'Info',
            text2: message,
            type: 'info',
            duration,
          },
        },
      });
  } catch (error) {
    // propper handeling required, with appLogger.error(error); OR errorsHandler(error);
    console.error('error ->', error);
  }
};

const deleteToast = (store, toast) => {
  try {
    store.dispatch({
      type: TOAST_DELETE,
      payload: {
        id: toast?.id,
        toast: {
          id: toast?.id,
          text1: toast?.text1,
          text2: toast?.text2,
          type: toast?.type,
        },
      },
    });
  } catch (error) {
    // propper handeling required, with appLogger.error(error); OR errorsHandler(error);
    console.error('error ->', error);
  }
};

export default { showError, showSuccess, showWarning, showInfo, deleteToast };
